/**
 * Semi-quick-and-dirty "Make v-dialog movable" directive.
 * Usage:
 *  - Add "v-drag-dialog" attribute to a "v-dialog" element, e.g.
 *      <v-dialog v-drag-dialog v-model="showDialog">
 *        <v-card>
 *          <v-card-title>Drag me</v-card-title>
 *          <v-card-text>I am some content</v-card-text>
 *        </v-card>
 *      </v-dialog>
 *  - By default, the "drag handle" for the dialog will be the first v-card-title in the dialog. This directive will
 *    add a "mousedown" listener to the drag handle, and set its cursor style to "move"
 *    If you want a different element to be the handle, then set its DOM query selector as the directive value, e.g.
 *      <v-dialog v-drag-dialog="'div#someid'">
 */

import Vue from 'vue'

const listeners: any = {}
let componentCount = 0 // Just a simple counter to keep track of listeners

// Quick-and-dirty: only works on a v-dialog
Vue.directive('drag-dialog', (el, binding, vnode: Vue.VNode) => {
  // Directive updates now happen *before* v-dialog creates its content. So we use setTimeout to bump
  // the initial setup to the end of the event queue
  setTimeout(() => {
    setup(el, binding, vnode)
  }, 100)
})

function setup (el, binding, vnode) {
  if (!vnode.componentInstance || !vnode.componentInstance.$refs.content) {
    return
  }
  const target = vnode.componentInstance.$refs.content as HTMLElement

  let key = el.getAttribute('draggable-uid')
  if (!key) {
    componentCount++
    key = '' + componentCount
    el.setAttribute('draggable-uid', key)
  }

  const hasListeners = !!listeners[key]
  const isActive = vnode.componentInstance?.$data.isActive

  if (isActive && hasListeners) {
    return
  }
  if (!isActive && !hasListeners) {
    return
  }

  if (isActive) {
    // By default, the "handle" (i.e. what the user clicks on to drag)
    // is the first v-card-title inside the dialog content
    const handleSelector = binding.value || '.v-card__title'
    startListening(target, key, handleSelector)
  }
  if (!isActive) {
    stopListening(key)
  }
}

function startListening (dialogContent: HTMLElement, key: string, handleSelector: string) {
  const moveThreshold = 100000
  let rAFPending = false
  const mouseStart = { x: 0, y: 0 }
  const mouseLast = { x: 0, y: 0 }
  const mouseDelta = { x: 0, y: 0 }
  const offset = { x: 0, y: 0 }
  let handleCheckCount = 0

  listeners[key] = {}

  dialogContent.style.transform = ''
  dialogContent.style.transition = 'transform 0s'

  // v-dialog content doesn't necessarily exist when the component directive first triggers.
  // So we bump this to the end of the event queue via setTimeout
  setTimeout(addHandle)

  function addHandle () {
    handleCheckCount++
    const handle = dialogContent.querySelector(handleSelector) as HTMLElement | null
    if (handle) {
      handle.style.cursor = 'move'
      addListener(handle, key, 'mousedown', mousedown)
    } else if (handleCheckCount <= 5) {
      // Dialog could still be initializing/loading, so try over the course of the next two seconds to find it.
      setTimeout(addHandle, 500)
    } else {
      // Failed after 5 tries. Clean up "listeners" entry so the code knows to try again if necessary
      delete listeners[key]
    }
  }

  function mousedown (ev: MouseEvent) {
    mouseDelta.x = mouseDelta.y = 0
    mouseStart.x = mouseLast.x = ev.pageX
    mouseStart.y = mouseLast.y = ev.pageY
    addListener(document, key, 'mousemove', mousemove)
    addListener(document, key, 'mouseup', mouseup)
  }

  function mousemove (ev: MouseEvent) {
    if (Math.abs(ev.pageX - mouseLast.x) > moveThreshold || Math.abs(ev.pageY - mouseLast.y) > moveThreshold) {
      // Cursor jumped waaay outside expected window, so abort dragging
      mouseup()
    } else {
      mouseLast.x = ev.pageX
      mouseLast.y = ev.pageY
      mouseDelta.x = ev.pageX - mouseStart.x
      mouseDelta.y = ev.pageY - mouseStart.y
      requestUpdateViewport()
    }
  }

  function mouseup () {
    // Set new "start" point next time the user starts a move
    offset.x += mouseDelta.x
    offset.y += mouseDelta.y
    // reset
    removeListener(key, 'mousemove')
    removeListener(key, 'mouseup')
  }

  function requestUpdateViewport () {
    if (!rAFPending) {
      rAFPending = true
      window.requestAnimationFrame(() => {
        dialogContent.style.transform = `translate(${offset.x + mouseDelta.x}px,${offset.y + mouseDelta.y}px)`
        rAFPending = false
      })
    }
  }
}

function stopListening (key: string) {
  if (!listeners[key]) {
    return
  }
  Object.keys(listeners[key]).forEach((eventKey) => {
    removeListener(key, eventKey)
  })

  delete listeners[key]
}

function addListener (target: HTMLElement | Document, key: string, eventKey: string, fn: any) {
  if (listeners[key][eventKey]) {
    return
  }
  target.addEventListener(eventKey, fn)
  listeners[key][eventKey] = { target, fn }
}

function removeListener (key: string, eventKey: string) {
  const listener = listeners[key][eventKey]
  if (!listener) {
    return
  }
  listener.target.removeEventListener(eventKey, listener.fn)
  delete listeners[key][eventKey]
}
