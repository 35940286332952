import { ActionTree, MutationTree } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import LRUCache from 'lru-cache'
import stringify from 'fast-json-stable-stringify'
import { Citation, CitationState, CitationWrapper, Demographics, RootState } from '~/types'

let internalSearchCount = 0
const searchCache = new LRUCache<string, any>({ max: 3 })

// We need this "emptyCitation" to ensure reactivity is initialized for all fields used on the EditCitation page
const emptyCitation: Citation = {
  appealFine: '',
  reducedFine: '',
  appealedByByuId: '',
  appealedDate: '',
  id: null,
  citationId: null /* TODO: remove this backwards-compatible field after all endpoints updated */,
  citationNumber: '',
  citationOwnerByuId: '',
  citationStatus: '',
  citeTagCode: '',
  citeTagDate: '',
  dateTimeUpdated: '',
  issuedDateTime: '',
  locationCode: '',
  needFinPost: false,
  officerId: '',
  originalFine: 0,
  paidByByuId: '',
  paidDate: '',
  trfDecalId: null,
  updatedByByuId: '',
  vehicleId: null,
  violationCode: '',
  violationTypeCode: '',
  voidCode: '',
  voidDate: '',
  adjFine: 0
}

export const undoVoidResultCodes = {
  SUCCESS: 'SUCCESS',
  CITATION_NOT_FOUND: 'CITATION_NOT_FOUND',
  VOIDED_BY_APPEAL: 'VOIDED_BY_APPEAL',
  CANNOT_FIND_PREVIOUS_AMOUNT: 'CANNOT_FIND_PREVIOUS_AMOUNT',
  INVALID_AMOUNT: 'INVALID_AMOUNT',
  MULTIPLE_APPEALS: 'MULTIPLE_APPEALS'
}

export const state = (): CitationState => ({
  selected: null,
  searchResults: [],
  pagination: { pages: 0, total: 0, currentPage: 1 },
  searchCriteria: null,
  saving: false,
  searching: false,
  selectedOwnerIsChanging: false
})

export const mutations: MutationTree<CitationState> = {
  setSelected (state, wrapper: CitationWrapper | null) {
    if (!wrapper) {
      state.selected = null
    } else {
      // Need to make sure all basic fields are initialized (i.e. not "undefined")
      // so that they're all properly reactive on the page
      const basic = Object.assign({}, emptyCitation, wrapper.basic)
      const selected = cloneDeep(wrapper)
      selected.basic = basic
      state.selected = selected
    }
  },
  setSelectedOwnerIsChanging (state, status: boolean) {
    state.selectedOwnerIsChanging = status
  },
  setSaving (state, saving: boolean) {
    state.saving = saving
  },
  setSearching (state, searching: boolean) {
    if (searching) {
      internalSearchCount++
    } else {
      internalSearchCount--
    }
    state.searching = !!internalSearchCount
  },
  setSearchCriteria (state, criteria) {
    state.searchCriteria = criteria
  },
  setSearchResults (state, results) {
    state.searchResults = results
    if (results.length === 1) {
      state.selected = results[0]
    }
  },
  setPagination (state, pagination) {
    state.pagination = pagination
  },
  setPage (state, page: number) {
    if (!state.pagination || page < 1 || page > state.pagination.pages) {
      return
    }
    state.pagination.currentPage = page
  },
  clearCache () {
    // Mainly for testing
    searchCache.clear()
  },
  resetSearch (state) {
    searchCache.clear()
    state.searchCriteria = null
  }
}

export const actions: ActionTree<CitationState, RootState> = {
  reload ({ state, commit, dispatch }, citationNumber) {
    // Make sure we're still on the same citation
    if (state.selected?.basic.citationNumber === citationNumber) {
      commit('setSearchCriteria', null)
      dispatch('search', { citationNumber })
    }
  },

  async search ({ state, commit, dispatch }, criteria: any) {
    const isPageChange = !!criteria.isPageChange
    delete criteria.isPageChange

    if (!criteria.fieldSets) {
      criteria.fieldSets = ['numAppeals', 'owner', 'paidBy', 'updater', 'vehicle']
    }
    criteria.sortBy = ['issuedDateTime', 'citationNumber']
    if (criteria.issuedDateTimeStart) {
      criteria.sortDesc = ['false', 'false']
    } else {
      // For every search EXCEPT for search by date, most recent citations should be at the start of the list
      criteria.sortDesc = ['true', 'false']
    }
    if (!isPageChange) {
      criteria.itemsPerPage = 20
      criteria.page = 1
      // Only caching in between page changes
      searchCache.clear()
    }

    if (isEqual(criteria, state.searchCriteria)) {
      // Same search criteria submitted twice in a row, so skip the actual search,
      // but do re-trigger the "loadResults" action which will re-set data
      if (state.searchResults) {
        dispatch('loadResults', state.searchResults)
      }
      return
    }

    commit('setSearchCriteria', criteria)
    const cacheKey = stringify(criteria)

    if (!isPageChange) {
      commit('setPagination', { pages: 0, total: 0, currentPage: 1 })
      commit('setSearchResults', [])
    }

    let data
    if (searchCache.has(cacheKey)) {
      data = searchCache.get(cacheKey)
    } else {
      commit('setSearching', true)
      data = await this.$axios
        .$get('citations', { params: criteria })
        .catch((err) => {
          commit('setSearchCriteria', null)
          throw err
        })
        .finally(() => commit('setSearching', false))
      searchCache.set(cacheKey, data)
    }

    if (!isEqual(criteria, state.searchCriteria)) {
      // New search started in the meantime, so ignore these results
      return
    }

    const results = data?.values || []
    results.forEach((result: CitationWrapper) => {
      if (result?.basic) {
        // "adjustedFine" is the minimum of appealFine and reducedFine
        // BUT either or both of those two Fines may be undefined, so we
        // have this cumbersome logic to parse that out
        if (result.basic.appealFine && result.basic.reducedFine) {
          result.basic.adjFine = Math.min(Number(result.basic.appealFine), Number(result.basic.reducedFine))
        } else if (result.basic.appealFine) {
          result.basic.adjFine = Number(result.basic.appealFine)
        } else if (result.basic.reducedFine) {
          result.basic.adjFine = Number(result.basic.reducedFine)
        }
      }
    })

    dispatch('loadResults', results)

    if (!isPageChange) {
      commit('setPagination', {
        pages: data?.pageInfo?.pageCount || 0,
        total: data?.pageInfo?.total || 0,
        currentPage: 1
      })
    }
  },

  clearPreviousSearch ({ commit }) {
    commit('setSearchCriteria', null)
    commit('setSearchResults', [])
  },

  changeSearchPage ({ state, commit, dispatch }, page: number) {
    commit('setPage', page)
    if (!state.searchCriteria || !state.searchCriteria.itemsPerPage) {
      return
    }

    const criteria = cloneDeep(state.searchCriteria)
    criteria.page = page
    criteria.isPageChange = true

    return dispatch('search', criteria)
  },

  loadResults ({ commit, dispatch }, results: CitationWrapper[]) {
    commit('setSearchResults', results)
    if (results.length === 0) {
      this.$dialog.warning({
        title: 'Not Found',
        text: 'No matching Citation records found',
        showClose: false,
        actions: ['OK']
      })
    }
    if (results.length === 1) {
      dispatch('select', results[0])
    }
  },

  select ({ commit, dispatch }, citation: CitationWrapper | null) {
    commit('setSelected', citation)
    if (citation) {
      // Setting (not clearing) a new citation, so make sure DemographicsCard matches
      dispatch('demographics/loadByByuId', citation.basic?.citationOwnerByuId, { root: true })
    }
  },

  demographicsChanged ({ state, dispatch }, person: Demographics | null) {
    // Changes to the DemographicsCard override pre-selected Citation
    if (state.selectedOwnerIsChanging || !state.selected) {
      return
    }
    const currentSelectedPerson = state.selected.basic?.citationOwnerByuId || ''
    const newPerson = person?.basic?.byuId || ''
    if (newPerson !== currentSelectedPerson) {
      dispatch('select', null)
    }
  },

  save ({ commit, dispatch }, saveCitation: Citation) {
    commit('setSaving', true)
    const citation: any = Object.keys(saveCitation).reduce((output, key) => {
      if ((key.includes('Date') || key.includes('Time')) && saveCitation[key] && !saveCitation[key].includes('T')) {
        output[key] = `${saveCitation[key]}T00:00:00`
      } else {
        // API doesn't like blank strings instead of null for nullable fields, but is always okay with a null instead
        // of a blank string. So replace any blank strings with null in output
        output[key] = saveCitation[key] === '' ? null : saveCitation[key]
      }
      return output
    }, {})
    if (citation.id !== -1) {
      dispatch('update', citation)
    } else {
      delete citation.id
      citation.needFinPost = true
      this.$axios
        .$post('citations', citation)
        // needs to call get to retrieve citation field sets
        .then(response => dispatch('reload', response.basic.citationNumber))
        .finally(() => commit('setSaving', false))
    }
  },

  update ({ commit, dispatch }, citation: Citation) {
    this.$axios
      .$put(`citations/${citation.id}`, citation)
      // needs to call get to retrieve citation field sets
      .then(response => dispatch('reload', response.basic.citationNumber))
      .finally(() => commit('setSaving', false))
  },

  async undoVoid ({ commit, dispatch }, { citationId, amount }) {
    if (!citationId) {
      return undoVoidResultCodes.CITATION_NOT_FOUND
    }

    commit('setSaving', true)
    let error: any = null
    await this.$axios
      .$post(`citations/${citationId}/undo-void`, { amount }, { noAutoError: true })
      // needs to call get to retrieve citation field sets
      .then(citation => dispatch('reload', citation?.basic?.citationNumber))
      // ESLint handle-callback-err doesn't currently recognize typescript "?." operator, so it wrongly thinks this
      // code ignores the `err` parameter
      // eslint-disable-next-line handle-callback-err
      .catch((err) => {
        error = err?.response?.data?.errorMessage || 'Unknown Error'
      })
      .finally(() => commit('setSaving', false))

    return error || undoVoidResultCodes.SUCCESS
  },

  async loadPhotos ({ dispatch }, citationId: number) {
    dispatch('loading', 'citations/photos', { root: true })

    const data = await this.$axios
      .$get(`citations/${citationId}/photos`)
      .finally(() => dispatch('unloaded', 'citations/photos', { root: true }))

    return data?.length ? data : []
  },

  async loadLogs ({ dispatch }, citationId: number) {
    dispatch('loading', 'citations/logs', { root: true })

    const data = await this.$axios
      .$get('citation-logs', {
        params: { citationId, fieldSets: ['owner', 'updater'], sortBy: ['dateTimeUpdated'], sortDesc: ['false'] }
      })
      .finally(() => dispatch('unloaded', 'citations/logs', { root: true }))

    return data?.values || []
  },

  async loadNotes ({ dispatch }, citationId: number) {
    dispatch('loading', 'citations/notes', { root: true })

    const data = await this.$axios
      .$get(`notes?citationId=${citationId}`)
      .finally(() => dispatch('unloaded', 'citations/notes', { root: true }))
    return data?.length ? data : []
  },

  ignorePhoto (_, photo: any) {
    this.$axios.$put(`citations/1/photos/${photo.id}`, { ignore: true })
  },

  restorePhotos ({ dispatch }, photos: any[]) {
    dispatch('loading', 'citations/photos', { root: true })
    const promises: Promise<any>[] = []
    photos.forEach((photo) => {
      promises.push(this.$axios.$put(`citations/1/photos/${photo.id}`, { ignore: false }))
    })
    return Promise.all(promises).finally(() => dispatch('unloaded', 'citations/photos', { root: true }))
  }
}
