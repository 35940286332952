import { ActionTree, MutationTree } from 'vuex'
import { Fine, FineState, RootState } from '~/types'

export const state = (): FineState => ({
  fines: [],
  fineTypes: [],
  fineCodesByType: {},
  fineHistory: [],
  finesFuture: [],
  newFineIsActive: false
})

export const mutations: MutationTree<FineState> = {
  setFines (state, fines) {
    state.fines = fines
  },
  setFineHistory (state, logs) {
    if (!logs) {
      state.fineHistory = []
    } else if (Array.isArray(logs)) {
      state.fineHistory = logs
    } else {
      // If only one is returned it is an object not an array
      state.fineHistory = [logs]
    }
  },
  setFutureFines (state, futureFines) {
    if (!futureFines) {
      state.finesFuture = []
    } else if (Array.isArray(futureFines)) {
      state.finesFuture = futureFines
    } else {
      state.finesFuture = [futureFines]
    }
  },
  activateNewFine (state) {
    state.newFineIsActive = !state.newFineIsActive
  }
}

export const actions: ActionTree<FineState, RootState> = {
  loading ({ dispatch }, key: string) {
    dispatch('loading', `fines/${key}`, { root: true })
  },
  loaded ({ dispatch }, key: string) {
    dispatch('loaded', `fines/${key}`, { root: true })
  },
  loadFailed ({ dispatch }, key: string) {
    dispatch('loadFailed', `fines/${key}`, { root: true })
  },
  unloaded ({ dispatch }, key: string) {
    dispatch('unloaded', `fines/${key}`, { root: true })
  },
  wrapLoading ({ dispatch }, { key, promise }: { key: string; promise: Promise<any> }) {
    return dispatch('wrapLoading', { key: `fines/${key}`, promise }, { root: true })
  },
  loadFines ({ commit, dispatch }) {
    const promise = this.$axios.$get('fines', { params: { codeExists: true } }).then((data) => {
      commit('setFines', data)
      const fineCodes: string[] = []
      const fines: Fine[] = []
      const futureFines: Fine[] = []
      data.forEach((fine) => {
        if (!fineCodes.includes(fine.fineType)) {
          fineCodes.push(fine.fineType)
        }
        if (fine.active) {
          fines.push(fine)
        } else {
          futureFines.push(fine)
        }
      })
      commit('setFutureFines', futureFines)
      commit('setFines', fines)
      dispatch('codes/load', fineCodes, { root: true })
    })
    dispatch('wrapLoading', { key: 'fines', promise })
  },
  loadFineHistory ({ commit, dispatch }, logRequest) {
    commit('setFineHistory', null)
    logRequest.fieldSets = ['basic', 'owner']
    const promise = this.$axios.$get('fine-logs', { params: logRequest }).then((response) => {
      const logsWithOwner = response?.values?.map?.(wrapper =>
        Object.assign(wrapper.basic, {
          owner: wrapper.owner
        })
      )
      commit('setFineHistory', logsWithOwner)
    })
    dispatch('wrapLoading', { key: 'history', promise })
  },
  loadFutureFines ({ commit, dispatch }, futureFineRequest) {
    commit('setFutureFines', null)
    if (!futureFineRequest) {
      futureFineRequest = {}
    }
    futureFineRequest.active = false
    const promise = this.$axios
      .$get('fines', { params: futureFineRequest })
      .then(futureFines => commit('setFutureFines', futureFines))
    dispatch('wrapLoading', { key: 'future', promise })
  },
  deleteFine ({ dispatch }, fine) {
    dispatch('loading', 'future')
    this.$axios
      .$delete(`fines/${fine.id}`)
      .then(() => {
        dispatch('unloaded', 'future')
        dispatch('loadFutureFines')
      })
      .catch(() => dispatch('loadFailed', 'future'))
  },
  saveFine ({ dispatch }, fine) {
    if (fine.effectiveDate && !fine.effectiveDate.includes('T')) {
      fine.effectiveDate += 'T00:00:00'
    }
    if (fine.id) {
      dispatch('updateFine', fine)
    } else {
      dispatch('loading', 'future')
      delete fine.id
      this.$axios
        .$post('fines', fine)
        .then(() => {
          dispatch('unloaded', 'future')
          dispatch('loadFutureFines')
        })
        .catch(() => dispatch('loadFailed', 'future'))
    }
  },
  updateFine ({ dispatch }, fine) {
    dispatch('loading', 'future')
    this.$axios
      .$put(`fines/${fine.id}`, fine)
      .then(() => {
        dispatch('loadFines')
        dispatch('loaded', 'future')
        dispatch('loadFineHistory', { fineType: fine.fineType, fineCode: fine.fineCode })
      })
      .catch(() => dispatch('loadFailed', 'future'))
  }
}
