import { ActionTree, GetterTree, MutationTree } from 'vuex'
import pickBy from 'lodash/pickBy'
import { LoadStatus, Note, ParkingZone, PrivilegeOverride, PrivilegeState, RootState } from '~/types'

export const state = (): PrivilegeState => ({
  parkingZones: [],
  overrides: [],
  createError: false
})

export const getters: GetterTree<PrivilegeState, RootState> = {
  nonElectricZones: state => state.parkingZones.filter(zone => !zone.zoneId.startsWith('E'))
}

export const mutations: MutationTree<PrivilegeState> = {
  setParkingZones (state, parkingZones: ParkingZone[]) {
    state.parkingZones = parkingZones
  },
  setOverrides (state, response: any) {
    let overrides = response?.values
    if (!overrides) {
      state.overrides = []
      return
    }
    if (!Array.isArray(overrides)) {
      // Move object into array
      overrides = [overrides]
    }
    overrides.forEach((override) => {
      // "promote" basic data into main object, and move other data down into "meta"
      const basic = override.basic
      override.basic = undefined
      override.meta = Object.assign({}, override)
      Object.assign(override, basic)
      if (override.meta.vehicle?.basic) {
        override.meta.vehicle = override.meta.vehicle.basic
      }
    })
    state.overrides = overrides
  },
  toggleCreateError (state) {
    state.createError = !state.createError
  },
  clearList (state) {
    state.overrides = []
  }
}

export const actions: ActionTree<PrivilegeState, RootState> = {
  clearList ({ commit, dispatch }) {
    commit('clearList')
    dispatch('unloaded', 'privileges', { root: true })
  },

  getParkingZones ({ rootState, commit, dispatch }) {
    if (
      rootState.loadStatus.parkingZones === LoadStatus.LOADED ||
      rootState.loadStatus.parkingZones === LoadStatus.LOADING
    ) {
      return
    }

    const promise = this.$axios.$get('parking-zones').then(data => commit('setParkingZones', data))
    return dispatch('wrapLoading', { key: 'parkingZones', promise }, { root: true })
  },

  getOverrides ({ commit, dispatch, rootState }, criteria: any) {
    // Only allow one search at a time
    if (rootState.loadStatus.privileges === LoadStatus.LOADING) {
      return
    }
    let promise
    if (criteria?.id) {
      promise = this.$axios.$get(`privilege-overrides/${criteria.id}`).then((data) => {
        if (data.id) {
          commit('setOverrides', { values: [{ basic: data }] })
        }
      })
    } else {
      promise = this.$axios
        .$get('privilege-overrides', { params: pickBy(criteria) })
        .then(data => commit('setOverrides', data))
    }
    return dispatch('wrapLoading', { key: 'privileges', promise }, { root: true })
  },

  async createOverride ({ dispatch }, { override, noteContent }) {
    const data = await this.$axios.$post('privilege-overrides', override).finally()
    if (data?.id && noteContent.length > 0) {
      dispatch('notes/save', { noteType: 'DEFAULT', privilegeOverrideId: data.id, noteContent }, { root: true })
    }
  },

  saveOverride (_, override: PrivilegeOverride) {
    return this.$axios.$put(`privilege-overrides/${override.id}`, override)
  },

  deleteOverride (_, id: number) {
    return this.$axios.$delete(`privilege-overrides/${id}`)
  },

  getNotes (_, overrideId: number) {
    if (!overrideId) {
      return []
    }
    return this.$axios
      .$get('notes', { params: { privilegeOverrideId: overrideId } })
      .then(data => data || [])
      .catch(() => [])
  },

  saveNote ({ dispatch }, note: Note) {
    return dispatch('notes/save', note, { root: true })
  }
}
