
import { Component, Vue, State, Getter } from 'nuxt-property-decorator'
import * as authn from '@byuweb/browser-oauth'
import AppealsIcon from '~/icons/appealsIcon.vue'
import CitationsIcon from '~/icons/citationsIcon.vue'
import DemographicsIcon from '~/icons/demographicsIcon.vue'
import MiscIcon from '~/icons/miscIcon.vue'
import MenuBtn from '~/components/layouts/MenuBtn.vue'
import PrivilegesIcon from '~/icons/privilegesIcon.vue'
import RoleCheckbox from '~/components/layouts/RoleCheckbox.vue'
import VehiclesIcon from '~/icons/vehiclesIcon.vue'
import { Person } from '~/types'
import DemographicsCard from '~/components/DemographicsCard.vue'

@Component({
  components: {
    DemographicsCard,
    AppealsIcon,
    CitationsIcon,
    DemographicsIcon,
    MenuBtn,
    MiscIcon,
    PrivilegesIcon,
    RoleCheckbox,
    VehiclesIcon
  }
})
export default class DefaultLayout extends Vue {
  @State authenticated!: boolean
  @State user!: Person
  @Getter username!: string

  showGroups = false
  groups = [
    { name: 'Dispatch', id: 'TRAFFIC-DISPATCH' },
    { name: 'Student', id: 'TRAFFIC-CLERK' },
    { name: 'Category 1', id: 'TRAFFIC-CAT1' },
    { name: 'Category 2', id: 'TRAFFIC-CAT2' },
    { name: 'Category 3', id: 'TRAFFIC-CAT3' },
    { name: 'Guest', id: 'TRAFFIC-GUEST' },
    { name: 'Appeal', id: 'TRAFFIC-APPEAL' }
  ]

  get isSandbox () {
    return this.user.rawUserInfo?.['http://wso2.org/claims/keytype'] === 'SANDBOX'
  }

  get roles () {
    return this.user.roles || {}
  }

  get inMiscMenu () {
    const route = this.$route?.name || ''
    return route === 'guest' || route === 'overnight' || route.startsWith('misc')
  }

  get pageAccess () {
    const route = this.$route?.name || ''
    return (
      route === 'index' ||
      this.roles.admin ||
      (this.roles.employee && (route === 'misc-paid' || !route.startsWith('misc'))) ||
      (this.roles.readonly && !route.startsWith('misc')) ||
      (this.roles.guest && route === 'guest') ||
      (this.roles.overnight && route === 'overnight')
    )
  }

  logout () {
    authn.logout()
  }
}
