import { ActionTree } from 'vuex'
import { GuestInfo, RootState } from '~/types'

export const actions: ActionTree<any, RootState> = {
  register (_, guest: GuestInfo) {
    return this.$axios.$post('guest-registration', guest).then(() => {
      this.$dialog.confirm({
        title: 'Success',
        text: 'Registration successful.<br/>You should receive a confirmation email shortly.',
        actions: ['OK']
      })
    })
  }
}
