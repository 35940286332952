
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class MenuBtn extends Vue {
  @Prop() page!: string

  get bindings () {
    const pageProps = {}

    if (this.page) {
      Object.assign(pageProps, {
        to: { name: this.page },
        class: { primary: this.$route.name === this.page }
      })
    }

    return { ...pageProps, ...this.$attrs }
  }
}
