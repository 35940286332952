import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { LoadStatus, Officer, OfficerState, RootState } from '~/types'

export const state = (): OfficerState => ({
  officers: []
})

export const getters: GetterTree<OfficerState, RootState> = {
  officerIdNameMap: state =>
    state.officers.reduce((output, officer) => {
      if (officer.id) {
        output[officer.id] = officer.name
      }
      return output
    }, {}),
  select: state =>
    state.officers.map(officer =>
      Object.assign(officer, {
        value: officer.id.toString(),
        text: `${officer.callNumber} - ${officer.name}`
      })
    ),
  active: (_, getters) => getters.select.filter(officer => officer.status === 'ACT'),
  inactive: (_, getters) => getters.select.filter(officer => officer.status === 'INA'),
  grouped: (_, getters) => [{ header: 'ACTIVE' }].concat(getters.active, { header: 'INACTIVE' }, getters.inactive)
}

export const mutations: MutationTree<OfficerState> = {
  setOfficers (state, list: Officer[]) {
    state.officers = list
  },
  updateOfficer (state, officer: Officer) {
    const found = state.officers.find(o => o.id === officer.id)
    if (found?.id) {
      Object.assign(found, officer)
    }
  },
  saveOfficer (state, officer) {
    state.officers.push(officer)
  },
  removeOfficer (state, officer: Officer) {
    state.officers = state.officers.filter(o => o.id !== officer.id)
  }
}

export const actions: ActionTree<OfficerState, RootState> = {
  load ({ rootState, commit, dispatch }) {
    if (rootState.loadStatus.officers === LoadStatus.LOADED || rootState.loadStatus.officers === LoadStatus.LOADING) {
      return
    }

    const promise = this.$axios.$get('officers').then(data =>
      commit(
        'setOfficers',
        data?.sort?.((a, b) => (a.status === b.status ? a.callNumber - b.callNumber : a.status > b.status ? 1 : -1))
      )
    )
    dispatch('wrapLoading', { key: 'officers', promise }, { root: true })
  },
  updateOfficer ({ commit, dispatch }, officer: Officer) {
    const promise = this.$axios.$put(`officers/${officer.id}`, officer).then(data => commit('updateOfficer', data))
    return dispatch('wrapLoading', { key: 'officers', promise }, { root: true })
  },
  saveOfficer ({ commit, dispatch }, officer) {
    const promise = this.$axios.$post('officers', officer).then(data => commit('saveOfficer', data))
    return dispatch('wrapLoading', { key: 'officers', promise }, { root: true })
  },
  removeOfficer ({ commit, dispatch }, officer: Officer) {
    const promise = this.$axios.$delete(`officers/${officer.id}`).then(() => commit('removeOfficer', officer))
    return dispatch('wrapLoading', { key: 'officers', promise }, { root: true })
  }
}
