
import { Component, Getter, namespace, State, Vue } from 'nuxt-property-decorator'
import { DemographicsState } from '~/types'

const demographicsStore = namespace('demographics')

@Component({})
export default class DemographicsCard extends Vue {
  @State demographics!: DemographicsState
  @Getter isLoading!: { [key: string]: boolean }
  @demographicsStore.Action personPreview!: (byuId: string | null) => void
  @demographicsStore.Action loadByByuId!: (byuId?: string) => void

  pinned: boolean = false
  showHeader: boolean = true

  get person () {
    return this.demographics.person
  }

  get preview () {
    return this.demographics.preview
  }

  closePreview () {
    this.personPreview(null)
  }

  selectPreview () {
    if (this.preview?.basic?.byuId) {
      this.loadByByuId(this.preview.basic.byuId)
    }
    this.closePreview()
  }
}
