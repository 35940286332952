import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _48ee1bd0 = () => interopDefault(import('../pages/citations.vue' /* webpackChunkName: "pages/citations" */))
const _1c0f9572 = () => interopDefault(import('../pages/demographics.vue' /* webpackChunkName: "pages/demographics" */))
const _8f65f37e = () => interopDefault(import('../pages/dev.vue' /* webpackChunkName: "pages/dev" */))
const _063ce184 = () => interopDefault(import('../pages/guest.vue' /* webpackChunkName: "pages/guest" */))
const _7b9d8120 = () => interopDefault(import('../pages/overnight.vue' /* webpackChunkName: "pages/overnight" */))
const _0a2b9e34 = () => interopDefault(import('../pages/privileges.vue' /* webpackChunkName: "pages/privileges" */))
const _2aa4cf6a = () => interopDefault(import('../pages/vehicles.vue' /* webpackChunkName: "pages/vehicles" */))
const _b2a714fa = () => interopDefault(import('../pages/appeals/adjudicated.vue' /* webpackChunkName: "pages/appeals/adjudicated" */))
const _7b50492b = () => interopDefault(import('../pages/appeals/create.vue' /* webpackChunkName: "pages/appeals/create" */))
const _23bfcc75 = () => interopDefault(import('../pages/appeals/history.vue' /* webpackChunkName: "pages/appeals/history" */))
const _3e058ffe = () => interopDefault(import('../pages/appeals/new.vue' /* webpackChunkName: "pages/appeals/new" */))
const _1c6e97ef = () => interopDefault(import('../pages/appeals/processed.vue' /* webpackChunkName: "pages/appeals/processed" */))
const _4444ab5c = () => interopDefault(import('../pages/appeals/reappeals.vue' /* webpackChunkName: "pages/appeals/reappeals" */))
const _13ba51c7 = () => interopDefault(import('../pages/misc/codes.vue' /* webpackChunkName: "pages/misc/codes" */))
const _723ee21a = () => interopDefault(import('../pages/misc/fines.vue' /* webpackChunkName: "pages/misc/fines" */))
const _ad8d0e24 = () => interopDefault(import('../pages/misc/letters.vue' /* webpackChunkName: "pages/misc/letters" */))
const _1d048128 = () => interopDefault(import('../pages/misc/officers.vue' /* webpackChunkName: "pages/misc/officers" */))
const _7318d83b = () => interopDefault(import('../pages/misc/paid.vue' /* webpackChunkName: "pages/misc/paid" */))
const _0b0bcf7e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/citations",
    component: _48ee1bd0,
    name: "citations"
  }, {
    path: "/demographics",
    component: _1c0f9572,
    name: "demographics"
  }, {
    path: "/dev",
    component: _8f65f37e,
    name: "dev"
  }, {
    path: "/guest",
    component: _063ce184,
    name: "guest"
  }, {
    path: "/overnight",
    component: _7b9d8120,
    name: "overnight"
  }, {
    path: "/privileges",
    component: _0a2b9e34,
    name: "privileges"
  }, {
    path: "/vehicles",
    component: _2aa4cf6a,
    name: "vehicles"
  }, {
    path: "/appeals/adjudicated",
    component: _b2a714fa,
    name: "appeals-adjudicated"
  }, {
    path: "/appeals/create",
    component: _7b50492b,
    name: "appeals-create"
  }, {
    path: "/appeals/history",
    component: _23bfcc75,
    name: "appeals-history"
  }, {
    path: "/appeals/new",
    component: _3e058ffe,
    name: "appeals-new"
  }, {
    path: "/appeals/processed",
    component: _1c6e97ef,
    name: "appeals-processed"
  }, {
    path: "/appeals/reappeals",
    component: _4444ab5c,
    name: "appeals-reappeals"
  }, {
    path: "/misc/codes",
    component: _13ba51c7,
    name: "misc-codes"
  }, {
    path: "/misc/fines",
    component: _723ee21a,
    name: "misc-fines"
  }, {
    path: "/misc/letters",
    component: _ad8d0e24,
    name: "misc-letters"
  }, {
    path: "/misc/officers",
    component: _1d048128,
    name: "misc-officers"
  }, {
    path: "/misc/paid",
    component: _7318d83b,
    name: "misc-paid"
  }, {
    path: "/",
    component: _0b0bcf7e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
