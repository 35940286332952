import { ActionTree } from 'vuex'
import { OvernightInfo, RootState } from '~/types'

export const actions: ActionTree<any, RootState> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  register (_, _overnight: OvernightInfo) {
    this.$dialog.warning({
      title: 'Not Yet Implemented',
      text: '&quot;Overnight Parking&quot; service is not yet active',
      actions: ['OK']
    })

    return false
    // return this.$axios.$post('overnight-registration', overnight).then(() => {
    //   this.$dialog.confirm({
    //     title: 'Success',
    //     text: 'Registration successful.<br/>You should receive a confirmation email shortly.',
    //     actions: ['OK']
    //   })
    // })
  }
}
