/*
 * Copyright 2018 Brigham Young University
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import get from 'lodash/get'
import camelcaseObjectDeep from 'camelcase-object-deep'

const pickFirst = (acc, curr) => acc || curr

function parseLinks (links) {
  const next = get(links, 'personsNext.href')
  const prev = get(links, 'personsPrev.href')
  return { next, prev }
}

function parseAddresses (addresses) {
  if (get(addresses, 'metadata.validationResponse.code') !== 200) {
    return null
  }
  return addresses.values.reduce((all, c) => {
    const data = [
      get(c, 'addressLine1.value', ''),
      get(c, 'addressLine2.value', ''),
      get(c, 'addressLine3.value', ''),
      get(c, 'addressLine4.value', '')
    ].filter(l => l.trim().length > 0)
    const key =
      {
        MAL: 'mailing',
        RES: 'residential',
        WRK: 'work',
        PRM: 'permanent'
      }[c.addressType.value] || c.addressType.value
    return Object.assign({}, all, { [key]: data })
  }, {})
}

function parseBasic (basic) {
  const name = get(basic, 'preferredName.value', get(basic, 'nameFnf.value', get(basic, 'identityName.value', '')))
  const sortName = get(basic, 'nameLnf.value', '')
  const byuId = get(basic, 'byuId.value', '')
  const netId = get(basic, 'netId.value', '')
  const displayName = byuId ? `${name} (${byuId})` : name
  const fullName = get(basic, 'nameFnf.value', get(basic, 'identityName.value', get(basic, 'preferredName.value', '')))
  const fullDisplayName = byuId ? `${fullName} (${byuId})` : fullName
  return {
    name,
    fullName,
    fullDisplayName,
    displayName,
    sortName,
    byuId,
    netId
  }
}

function parseEmailAddresses (emailAddresses) {
  if (get(emailAddresses, 'metadata.validationResponse.code') !== 200) {
    return null
  }
  return emailAddresses.values
    .map(e => get(e, 'emailAddress.value', ''))
    .filter(e => !!e)
    .reduce(pickFirst, '')
}

function parsePhones (phones) {
  if (get(phones, 'metadata.validationResponse.code') !== 200) {
    return null
  }
  return phones.values
    .map(p => get(p, 'phoneNumber.value', ''))
    .filter(p => !!p)
    .reduce(pickFirst, '')
}

function parseEmployeeSummaries (employeeSummaries) {
  if (get(employeeSummaries, 'metadata.validationResponse.code') !== 200) {
    return null
  }
  return {
    employeeType: get(employeeSummaries, 'employeeRole.value'),
    department: get(employeeSummaries, 'department.value'),
    jobTitle: get(employeeSummaries, 'jobCode.description')
  }
}

function parseStudentSummaries (studentSummaries) {
  if (get(studentSummaries, 'metadata.validationResponse.code') !== 200) {
    return null
  }
  const studentStatus = get(studentSummaries, 'studentStatus.value')
  return { studentStatus }
}

function parsePerson (data) {
  if (!(data instanceof Object)) {
    data = {}
  }
  return Object.assign(
    {
      addresses: parseAddresses(data.addresses),
      email: parseEmailAddresses(data.emailAddresses),
      phone: parsePhones(data.phones),
      birthdate: get(data, 'vitalRecord.dateOfBirth.value')
    },
    parseBasic(data.basic),
    parseEmployeeSummaries(data.employeeSummary),
    parseStudentSummaries(data.studentSummary)
  )
}

export default function (data) {
  if (data instanceof Object) {
    data = camelcaseObjectDeep(data)
  }
  const people = data?.values?.map(parsePerson) ?? []
  const { next, prev } = parseLinks(data?.links)
  return { next, prev, people }
}
