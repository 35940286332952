
import { Component, Mutation, Prop, State, Vue } from 'nuxt-property-decorator'
import { Person } from '~/types'

@Component
export default class RoleCheckbox extends Vue {
  @State user!: Person
  @Prop() field!: string
  @Mutation toggleUserRole!: (role: string) => void

  get val () {
    return this.user.roles?.[this.field]
  }

  set val (_) {
    this.toggleUserRole(this.field)
  }
}
