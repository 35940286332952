import Vue from 'vue'
import moment from 'moment'
import { ActionTree, MutationTree } from 'vuex'
import { Appeal, AppealResult, AppealState, Letter, Note, RootState } from '~/types'

export const voidDecisionCodes = ['00', '01', '02', '03', '07', '10', '15', '3']

export const statusMap = {
  NEW: 'NEWAPPEAL',
  ADJUDICATED: 'ADJUD',
  PROCESSED: 'PROCESSED',
  REAPPEAL: 'REAPNEW',
  FINISHED: 'LTRSENT',
  SIGNED: 'SIGNED',
  REAPPEAL_LETTER_SENT: 'REAPLTRSEN',
  REAPPEAL_FINAL: 'REAPFINAL'
}

export const state = (): AppealState => ({
  saving: false,
  selected: {
    new: null,
    adjudicated: null,
    processed: null,
    reappeal: null
  },
  related: {
    new: []
  }
})

export const mutations: MutationTree<AppealState> = {
  setSaving (state, saving) {
    state.saving = saving
  },
  setSelected (state, { type, appealResult }) {
    state.selected[type] = appealResult
    state.related[type] = []
  },
  setRelated (state, { type, citations }) {
    state.related[type] = citations
  }
}

export const actions: ActionTree<AppealState, RootState> = {
  search ({ dispatch }, criteria) {
    // API is picky, doesn't like empty strings for some fields (e.g. dates) but allows null
    // where an empty string might be. So we simply replace *all* empty strings with null
    const cleanCriteria: any = Object.keys(criteria).reduce((output, key) => {
      if (criteria[key] !== '') {
        output[key] = criteria[key]
      }
      return output
    }, {})
    cleanCriteria.fieldSets = ['citation', 'appealPerson']

    const promise = this.$axios.$get('appeals', { params: cleanCriteria }).then(basicToAppeal)
    return dispatch('wrapLoading', { key: 'appeals/search', promise }, { root: true })
  },

  loadByStatus ({ dispatch }, status) {
    const promise = this.$axios
      .$get('appeals', {
        params: {
          status,
          fieldSets: ['citation', 'appealPerson']
        }
      })
      .then(basicToAppeal)
      .then(results => results.values)
    return dispatch('wrapLoading', { key: `appeals/${status}`, promise }, { root: true })
  },

  async findRelatedCitations ({ state, commit, dispatch }) {
    if (!state.selected.new?.citation?.citationOwnerByuId) {
      return
    }

    const ownerId = state.selected.new.citation.citationOwnerByuId

    dispatch('loading', 'appeals/related', { root: true })
    const response = await this.$axios
      .$get('citations', {
        params: {
          citationOwnerByuId: ownerId,
          sortBy: ['issuedDateTime', 'citationNumber'],
          sortDesc: ['true', 'false'],
          fieldSets: ['numAppeals', 'owner', 'vehicle']
        }
      })
      .finally(() => {
        if (state.selected.new?.citation?.citationOwnerByuId === ownerId) {
          dispatch('unloaded', 'appeals/related', { root: true })
        }
      })

    const citations = response?.values?.length ? response.values : []

    // Make sure currently-selected citation has not changed
    if (state.selected.new?.citation?.citationOwnerByuId === ownerId) {
      commit('setRelated', { type: 'new', citations })
    }
  },

  async findByCitationId ({ dispatch }, citationId: number): Promise<AppealResult[]> {
    dispatch('loading', 'citations/appeals', { root: true })

    const data = await this.$axios
      .$get('appeals', {
        params: {
          citationIds: citationId,
          sortBy: 'id',
          sortDesc: false
        }
      })
      .then(basicToAppeal)
      .then(results => results.values)
      .finally(() => dispatch('unloaded', 'citations/appeals', { root: true }))

    return data?.length ? data : []
  },

  async loadNote ({ dispatch }, appealId: number): Promise<Note> {
    dispatch('loading', 'appeals/notes', { root: true })

    const data = await this.$axios
      .$get('notes', { params: { appealId } })
      .finally(() => dispatch('unloaded', 'appeals/notes', { root: true }))

    return data?.[0]?.id ? data[0] : {}
  },

  async loadLetter ({ dispatch }, appealId: number): Promise<Letter> {
    dispatch('loading', 'appeals/letters', { root: true })

    const data = await this.$axios
      .$get(`appeals/${appealId}/letters`)
      .finally(() => dispatch('unloaded', 'appeals/letters', { root: true }))

    // return data?.[0]
    /*
     TODO: Once Appeals are converted to the new REST API, no need for "letterId" backwards compatibility.
     At that time, uncomment the previous line and delete all the following lines from this function
    */

    const record = data?.[0]
    if (record?.id) {
      record.letterId = record.id
    }

    return record
  },

  async loadAllLetters ({ dispatch }, appealId: number): Promise<Letter[]> {
    dispatch('loading', 'appeals/letters', { root: true })

    const data = await this.$axios
      .$get(`appeals/${appealId}/letters`)
      .finally(() => dispatch('unloaded', 'appeals/letters', { root: true }))

    // return data || []
    /*
     TODO: Once Appeals are converted to the new REST API, no need for "letterId" backwards compatibility.
     At that time, uncomment the previous line and delete all the following lines from this function
    */

    const records = data || []
    records.forEach(letter => (letter.letterId = letter.id))

    return records
  },

  updateAppeal ({ commit }, basic: Appeal) {
    commit('setSaving', true)
    return this.$axios.$put(`appeals/${basic.id}`, { basic }).finally(() => commit('setSaving', false))
  },

  deleteAppeals ({ commit }, appeals: AppealResult[]) {
    commit('setSaving', true)

    const promises = appeals
      .filter(appeal => appeal.appeal?.id)
      .map(appeal => this.$axios.$delete(`appeals/${appeal.appeal?.id}`))

    Promise.all(promises).finally(() => commit('setSaving', false))
  },

  undoProcessed ({ commit }, result: AppealResult) {
    const basic = Object.assign({}, result.appeal, { appealStatus: statusMap.ADJUDICATED })
    commit('setSaving', true)
    return this.$axios
      .$put(`appeals/${basic.id}`, {
        basic,
        letter: result.letter
      })
      .finally(() => commit('setSaving', false))
  },

  undoDecision ({ commit }, citationId: string) {
    commit('setSaving', true)
    return this.$axios
      .$post(`citations/${citationId}/undo-void`, { resetAppeal: true })
      .finally(() => commit('setSaving', false))
  },

  signSubmitReappeal ({ commit }, result: AppealResult) {
    const basic: Appeal = Object.assign({}, result.appeal, { appealStatus: statusMap.REAPPEAL_FINAL })

    commit('setSaving', true)
    return this.$axios
      .$put(`appeals/${basic.id}`, {
        basic,
        citation: result.citation,
        letter: result.letter
      })
      .finally(() => commit('setSaving', false))
  },

  saveFromNew ({ commit }, result: AppealResult) {
    const basic: Appeal = Object.assign({}, result.appeal, { appealStatus: statusMap.ADJUDICATED })

    commit('setSaving', true)
    return this.$axios
      .$put(`appeals/${basic.id}`, { basic, note: result.note, citation: result.citation })
      .finally(() => commit('setSaving', false))
  },

  saveFromAdjudicated ({ commit }, result: AppealResult) {
    const basic: Appeal = Object.assign({}, result.appeal, { appealStatus: statusMap.PROCESSED })

    commit('setSaving', true)
    return this.$axios
      .$put(`appeals/${basic.id}`, {
        basic,
        citation: result.citation,
        note: result.note,
        letter: result.letter
      })
      .finally(() => commit('setSaving', false))
  },

  signSubmit ({ commit }, { result, actions }: { result: AppealResult; actions: any }) {
    const flags: string[] = []
    if (actions.sendEmail) {
      flags.push('SEND_EMAIL')
    }
    if (actions.printLetter) {
      flags.push('PRINT_LETTER')
    }

    const basic = Object.assign({}, result.appeal, { appealStatus: statusMap.FINISHED })

    commit('setSaving', true)
    return this.$axios
      .$put(`appeals/${basic.id}`, {
        basic,
        citation: result.citation,
        note: result.note,
        letter: result.letter,
        flags
      })
      .finally(() => commit('setSaving', false))
  },

  createAppeal ({ commit }, appeal: Appeal) {
    const data = Object.assign({}, appeal, {
      appealDate: moment().toISOString()
    })

    commit('setSaving', true)
    return this.$axios.$post('appeals', data).finally(() => commit('setSaving', false))
  },

  async generateLetter (
    { rootState, rootGetters },
    {
      appealResult,
      templateTypeId,
      groupId,
      groupName
    }: { appealResult: AppealResult; templateTypeId: string; groupId?: string; groupName?: string }
  ) {
    if (!appealResult?.appeal?.appealId) {
      return this.$dialog.error({ text: 'Appeal not selected', icon: false })
    }

    let letter: string | undefined = rootGetters['letters/contentMap'][templateTypeId]
    if (!letter) {
      return this.$dialog.error({ text: 'Letter Template not found', icon: false })
    }

    if (letter.includes('<NEWDATE>') || letter.includes('[NEW-DATE]')) {
      let newDate = await this.$dialog.prompt({
        title: 'Appeals - Date',
        text: 'Date (e.g. 4/27/2019):',
        value: moment().format('M/D/YYYY')
      })
      if (!newDate) {
        newDate = '[DATE]'
      }
      letter = letter.replace(/\[NEW-DATE]|<NEWDATE>/g, newDate)
    }

    if (letter.includes('<NEWTIME>') || letter.includes('[NEW-TIME]')) {
      let newTime = await this.$dialog.prompt({
        title: 'Appeals - Time',
        text: 'Time (e.g. 1:00 PM):',
        value: '1:00 PM'
      })
      if (!newTime) {
        newTime = '[TIME]'
      }
      letter = letter.replace(/\[NEW-TIME]|<NEWTIME>/g, newTime)
    }

    letter = letter.replace(/\[DATE]|<D>/g, moment().format('MMMM DD, YYYY'))
    if (groupId && !groupName) {
      groupName = rootGetters['codes/codeMap']('APG')?.[groupId]
    }
    if (groupName) {
      letter = letter.replace(/\[APPEAL-GROUP]|<H>/g, groupName)
    }
    letter = letter.replace(
      /\[RE-CITATION-NUMBER]|<C>/g,
      `RE: Citation # ${appealResult.citation?.citationNumber || ''}`
    )
    letter = letter.replace(
      /\[DECISION-DATE]|<PD>/g,
      moment(appealResult.appeal.decisionDate || undefined).format('MMMM DD, YYYY')
    )
    if (
      appealResult.appeal &&
      appealResult.citation &&
      appealResult.appeal.appealFineAmount !== appealResult.citation.originalFine
    ) {
      letter = letter.replace(
        /\[FINE-REDUCTION-MESSAGE]|<F>/g,
        `However the fine has been reduced to $${appealResult.appeal.appealFineAmount}. I hope this mitigation may help in some small way.`
      )
    }
    letter = letter.replace(/\[FINE-AMOUNT]|<FINE>|<FINE-AMOUNT>/g, '$' + appealResult.appeal.appealFineAmount)
    letter = letter.replace(/\[ORIGINAL-FINE]|<ORIGFINE>/g, '$' + appealResult.citation?.originalFine)

    const person = rootState.demographics?.person
    if (appealResult.appeal.appealedByByuId && person?.basic?.byuId === appealResult.appeal.appealedByByuId) {
      const name = person.basic.name || ''
      letter = letter.replace(/\[NAME]|<N>/g, name)
      letter = letter.replace(/\[GREETING-WITH-NAME]|<G>/g, `Dear ${name}:`)
      letter = letter.replace(/\[BYU-ID]|<B>/g, person.basic.byuId)
      const addressLines: string[] = []
      if (person.addresses?.length) {
        // Find mailing address, and concatenate non-blank address lines
        const mailingAddresses = person.addresses.filter(address => address?.type === 'MAL')
        if (mailingAddresses[0]) {
          for (let i = 1; i < 5; i++) {
            const line = mailingAddresses[0][`line${i}`]?.trim?.()
            if (line) {
              addressLines.push(line)
            }
          }
        }
      }
      letter = letter.replace(/\[ADDRESS]|<A>/g, addressLines.join('<br/>'))
    }

    Vue.set(appealResult, 'letter', {
      appealId: appealResult.appeal.appealId,
      letterId: appealResult.letter?.id || appealResult.letter?.letterId,
      letterType: 'NEW',
      byuId: appealResult.appeal.appealedByByuId,
      letterContent: letter
    })
  }
}

const basicToAppeal = (results) => {
  if (results?.values?.forEach) {
    results.values.forEach((result) => {
      result.appeal = result.basic
      delete result.basic
      // TODO: remove the next bit after *all* the Appeal and Citation endpoints are updated
      // TEMPORARY for backwards compatibility
      if (result.appeal?.id) {
        result.appeal.appealId = result.appeal.id
      }
    })
  }
  return results
}
