import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import LRUCache from 'lru-cache'
import {
  Demographics,
  Note,
  Person,
  RootState,
  TowFlag,
  Vehicle,
  VehicleResponse,
  VehicleSearchCriteria,
  VehicleState
} from '~/types'

let selectedParams: Vehicle = {}
const findCache = new LRUCache({ max: 10 })

export const state = (): VehicleState => ({
  selected: null,
  edit: null,
  saving: false,
  active: [],
  inactive: [],
  historic: [],
  searchCriteria: null,
  fixPlate (rawPlate?: string, stateCode?: string): string {
    if (!rawPlate) {
      return ''
    }

    const plate = rawPlate.toUpperCase().replace(/[^A-Z0-9]/g, '')
    if (stateCode === 'UT') {
      return plate.replace(/O/g, '0')
    }

    return plate
  }
})

function extractVehiclesFromApiResponse (vehicleResponse: VehicleResponse): Vehicle[] {
  return (vehicleResponse?.values || [])
    .filter(wrapper => wrapper?.basic?.id)
    .map(wrapper =>
      Object.assign(wrapper.basic, {
        campusOwner: wrapper.owner
      })
    )
}

export const getters: GetterTree<VehicleState, RootState> = {
  selectedIsNew: state => state.selected?.id === -1,
  isPersonSearch: state => state.searchCriteria?.campusOwnerByuId,
  unsavedChanges: state =>
    state.edit &&
    state.selected &&
    Object.keys(state.edit).reduce((current, key) => current || state.edit![key] !== state.selected![key], false)
}

export const mutations: MutationTree<VehicleState> = {
  setSelected (state, vehicle: Vehicle | null) {
    if (vehicle === null) {
      state.selected = vehicle
      state.edit = vehicle
    } else {
      state.selected = vehicle
      state.edit = cloneDeep(state.selected)
    }
  },
  updateSelected (state, vehicle: Vehicle) {
    if (state.selected?.id !== vehicle?.id) {
      // Currently selected vehicle no longer matches the vehicle we're trying to "update"
      return
    }
    state.selected = { ...state.selected, ...vehicle }
  },
  updateEditField (state, { field, value }) {
    if (state.edit) {
      state.edit[field] = value
    }
  },
  setList (state, { type, vehicles }: { type: string; vehicles: Vehicle[] }) {
    state[type] = vehicles
  },
  setSearchCriteria (state, criteria: VehicleSearchCriteria) {
    state.searchCriteria = criteria
  },
  clearSearch (state) {
    state.searchCriteria = null
    state.active = []
    state.inactive = []
    state.historic = []
  },
  setSaving (state, saving: boolean) {
    state.saving = saving
  },
  updateNotes (state, notes: Note[]) {
    if (!state.selected) {
      return
    }
    state.selected.notes = notes
  },
  removeNote (state, note: Note) {
    if (!note.id || !state.selected || !state.selected.notes) {
      return
    }
    state.selected.notes = state.selected.notes.filter((item) => {
      return item.id !== note.id
    })
  },
  updateTowFlag (state, towflag: TowFlag) {
    state.selected!.towFlag = towflag
    state.edit!.towFlag = cloneDeep(towflag)
  }
}

export const actions: ActionTree<VehicleState, RootState> = {
  // Convenience wrappers
  loading ({ dispatch }, key: string) {
    dispatch('loading', `vehicles/${key}`, { root: true })
  },
  loaded ({ dispatch }, key: string) {
    dispatch('loaded', `vehicles/${key}`, { root: true })
  },
  unloaded ({ dispatch }, key: string) {
    dispatch('unloaded', `vehicles/${key}`, { root: true })
  },
  loadFailed ({ dispatch }, key: string) {
    dispatch('loadFailed', `vehicles/${key}`, { root: true })
  },

  select ({ commit, dispatch }, vehicle: Vehicle | null) {
    commit('setSelected', vehicle)
    if (!vehicle) {
      dispatch('unloaded', 'selected')
      dispatch('unloaded', 'additionalInfo')
    } else {
      dispatch('loaded', 'selected')
      dispatch('loaded', 'additionalInfo')
    }
  },
  demographicsChanged ({ state, dispatch }, person: Demographics | null) {
    // Changes to the DemographicsCard override pre-selected Vehicle
    if (!state.selected) {
      return
    }
    const currentSelectedPerson = state.selected.campusOwnerByuId || ''
    const newPerson = person?.basic?.byuId || ''
    if (newPerson !== currentSelectedPerson) {
      dispatch('select', null)
    }
  },
  create ({ dispatch }, vehicle: Vehicle) {
    // Pre-populate fields that definitely require reactivity
    const newVehicle = Object.assign(
      {
        licensePlate: '',
        stateCode: ''
      },
      vehicle
    )
    newVehicle.id = -1
    dispatch('select', newVehicle)
  },
  fillVehicle ({ dispatch }, { id, obj, field }) {
    if (!obj) {
      return
    }
    if (!(field in obj)) {
      Vue.set(obj, field, {})
    }
    if (obj[field]?.id === id) {
      // Already filled in
      return
    }
    obj[field] = { stateCode: '', licensePlate: 'Loading...' }
    dispatch('findById', id)
      .then((vehicle: Vehicle) => {
        obj[field] = vehicle
      })
      .catch(() => {})
  },
  findById (_, id: number) {
    if (!id) {
      return {}
    }

    if (findCache.has(id)) {
      return findCache.get(id)
    }

    const promise = this.$axios
      .$get(`vehicles/${id}`)
      .then((data) => {
        const output = data?.basic || {}
        findCache.set(id, output)
        return output
      })
      .catch(() => {
        findCache.delete(id)
        return null
      })

    findCache.set(id, promise)
    return promise
  },
  async load ({ commit, dispatch }, vehicle: Vehicle) {
    dispatch('select', null)
    if (!vehicle.id && (!vehicle.stateCode || !vehicle.licensePlate)) {
      dispatch('loadFailed', 'selected')
      return
    }

    selectedParams = vehicle
    dispatch('loading', 'selected')

    const promise: Promise<any> = this.$axios.$get('vehicles', {
      params: {
        id: vehicle.id,
        stateCode: vehicle.stateCode,
        licensePlate: vehicle.licensePlate,
        fieldSets: ['basic', 'owner']
      }
    })
    if (vehicle.campusOwnerByuId) {
      dispatch('demographics/loadByByuId', vehicle.campusOwnerByuId, { root: true })
    } else {
      commit('demographics/setPerson', null, { root: true })
    }

    const data = await promise
      .then((data) => {
        const vehicles = extractVehiclesFromApiResponse(data)
        return vehicles.length > 0 ? vehicles[0] : null
      })
      .catch(() => dispatch('loadFailed', 'selected'))
    if (!isEqual(selectedParams, vehicle)) {
      // Different vehicle selected while we were "await"ing, so throw away results
      return
    }
    if (!data || !data.id) {
      dispatch('loadFailed', 'selected')
      return
    }
    const basicInfo: Vehicle = data
    if (!vehicle.campusOwnerByuId && basicInfo.campusOwnerByuId) {
      dispatch('demographics/loadByByuId', basicInfo.campusOwnerByuId, { root: true })
    }
    dispatch('select', basicInfo)

    dispatch('loadAdditionalInfo')
  },

  loadAdditionalInfo ({ dispatch }) {
    dispatch('loadCurrentPrivilege')
    dispatch('loadStateOwner')
    dispatch('loadTowFlags')
    dispatch('loadPrivilegeOverrides')
    dispatch('loadVehicleHistory')
    dispatch('loadCitationHistory')
    dispatch('reloadNotes')
  },

  loadCurrentPrivilege ({ state, commit, dispatch }) {
    const vehicle = state.selected
    if (!vehicle?.id) {
      return
    }
    const promise = this.$axios.$get('privileges', { params: { vehicleId: vehicle.id } }).then((vehiclePriv) => {
      commit('updateSelected', { id: vehicle.id, vehiclePriv: vehiclePriv.zoneDesc })
    })
    dispatch('wrapLoading', { key: 'vehicles/currentPrivilege', promise }, { root: true })
  },

  loadStateOwner ({ state, commit, dispatch }) {
    const vehicle = state.selected
    if (vehicle?.stateOwner || !vehicle?.stateOwnerByuId) {
      return
    }
    const promise = dispatch('person/searchByByuId', vehicle.stateOwnerByuId, { root: true }).then((person) => {
      commit('updateSelected', { id: vehicle.id, stateOwner: person })
    })
    dispatch('wrapLoading', { key: 'vehicles/stateOwner', promise }, { root: true })
  },

  loadTowFlags ({ state, commit, dispatch }) {
    const vehicleId = state.selected?.id
    if (!vehicleId) {
      return
    }
    const promise = this.$axios.$get('tow-flags', { params: { vehicleId } }).then((towFlags) => {
      commit('updateSelected', { id: vehicleId, towFlag: towFlags?.values?.[0]?.basic })
    })
    dispatch('wrapLoading', { key: 'vehicles/towFlag', promise }, { root: true })
  },

  loadPrivilegeOverrides ({ state, commit, dispatch }) {
    const vehicle = state.selected
    if (!vehicle?.id) {
      return
    }

    const vehiclePrivPromise = this.$axios.$get('privilege-overrides', { params: { vehicleId: vehicle.id } })
    const personPrivPromise = vehicle.campusOwnerByuId
      ? this.$axios.$get('privilege-overrides', { params: { byuId: vehicle.campusOwnerByuId } })
      : Promise.resolve()

    const promise = Promise.all([vehiclePrivPromise, personPrivPromise]).then((results) => {
      const overrideHistory: any[] = []
      const allPrivileges = (results[0]?.values || []).concat(results[1]?.values || [])
      const map = new Map() // Just used to filter out duplicates
      for (const privilege of allPrivileges) {
        if (!map.has(privilege.basic.id)) {
          map.set(privilege.basic.id, true)
          overrideHistory.push(privilege.basic)
        }
      }
      commit('updateSelected', { id: vehicle.id, overrideHistory })
    })
    dispatch('wrapLoading', { key: 'vehicles/privilegeOverrides', promise }, { root: true })
  },

  loadVehicleHistory ({ state, commit, dispatch }) {
    const vehicleId = state.selected?.id
    if (!vehicleId) {
      return
    }
    const promise = this.$axios.$get('vehicle-logs', { params: { id: vehicleId } }).then((history) => {
      commit('updateSelected', { id: vehicleId, history })
    })
    dispatch('wrapLoading', { key: 'vehicles/vehicleHistory', promise }, { root: true })
  },

  loadCitationHistory ({ state, commit, dispatch }) {
    const vehicleId = state.selected?.id
    if (!vehicleId) {
      return
    }
    const promise = this.$axios
      .$get('citations', { params: { vehicleId, fieldSets: ['vehicle', 'owner'] } })
      .then((citations) => {
        if (citations?.values?.length) {
          commit('updateSelected', { id: vehicleId, citationHistory: citations?.values })
        }
      })
    dispatch('wrapLoading', { key: 'vehicles/citationHistory', promise }, { root: true })
  },

  clearSearch ({ commit, dispatch }) {
    commit('clearSearch')
    dispatch('unloaded', 'active')
    dispatch('unloaded', 'inactive')
    dispatch('unloaded', 'historic')
  },

  setList ({ commit, dispatch }, { type, vehicles }: { type: string; vehicles: Vehicle[] }) {
    commit('setList', { type, vehicles })
    dispatch('loaded', type)
  },

  searchByCriteria ({ state, commit, dispatch }, criteria: any) {
    dispatch('clearSearch')
    commit('setSearchCriteria', criteria)
    criteria.fieldSets = ['basic', 'owner']
    dispatch('loading', 'active')
    this.$axios
      .$get('vehicles', { params: criteria })
      .then((data) => {
        if (!isEqual(state.searchCriteria, criteria)) {
          // Search parameters have changed, so completely throw out
          // these results
          return
        }
        const vehicles = extractVehiclesFromApiResponse(data)
        dispatch('setList', { type: 'active', vehicles })
      })
      .catch(() => dispatch('loadFailed', 'active'))
  },

  searchByPerson ({ state, commit, dispatch }, owner: Person) {
    const byuId = owner.byuId
    dispatch('clearSearch')
    dispatch('loading', 'active')
    dispatch('loading', 'inactive')
    dispatch('loading', 'historic')

    const criteria: any = { campusOwnerByuId: byuId }
    commit('setSearchCriteria', criteria)
    criteria.fieldSets = ['basic', 'owner']
    this.$axios
      .$get('vehicles', { params: criteria })
      .then((data) => {
        if (!state.searchCriteria || state.searchCriteria.campusOwnerByuId !== byuId) {
          // Criteria has changed since search started
          return
        }
        const vehicles = extractVehiclesFromApiResponse(data)
        dispatch('setList', { type: 'active', vehicles })
      })
      .catch(() => dispatch('loadFailed', 'active'))

    const inactive = this.$axios
      .$get('inactive-vehicles', { params: { byuId } })
      .then((vehicles) => {
        if (!state.searchCriteria || state.searchCriteria.campusOwnerByuId !== byuId) {
          // Criteria has changed since search started
          return
        }
        dispatch('setList', { type: 'inactive', vehicles })
      })
      .catch(() => dispatch('loadFailed', 'inactive'))

    const historic = this.$axios
      .$get('historic-vehicles', { params: { campusOwnerByuId: byuId } })
      .then((vehicles) => {
        if (!state.searchCriteria || state.searchCriteria.campusOwnerByuId !== byuId) {
          // Criteria has changed since search started
          return
        }

        dispatch('setList', { type: 'historic', vehicles })
      })
      .catch(() => dispatch('loadFailed', 'historic'))

    Promise.all([inactive, historic]).then(() => {
      if (!state.inactive.length || !state.historic.length) {
        return
      }

      // Filter any "Inactive" vehicles from "Historic" list (which includes "Inactive" vehicles by default)
      const inactives = state.inactive.map(vehicle => `${vehicle.stateCode} - ${vehicle.licensePlate}`)
      const vehicles = state.historic.filter(
        vehicle => !inactives.includes(`${vehicle.stateCode} - ${vehicle.licensePlate}`)
      )
      dispatch('setList', { type: 'historic', vehicles })
    })
  },

  searchTowList ({ dispatch }, { state, licensePlate }) {
    const promise = this.$axios
      .$get('tow-flags', {
        params: {
          stateCode: state,
          licensePlate,
          flagTypeCode: 'TOW',
          fieldSets: 'vehicle',
          sortBy: 'stateCode,licensePlate',
          sortDesc: 'false,false',
          itemsPerPage: 20
        }
      })
      .then((data) => {
        return data.values
      })
    return dispatch('wrapLoading', { key: 'vehicles/towList', promise }, { root: true })
  },

  saveVehicle ({ commit, dispatch }, vehicle: Vehicle) {
    findCache.clear() // Clear "find by ID" results after vehicle save
    commit('setSaving', true)

    // API is picky, doesn't like empty strings for some fields (e.g. dates) but allows null
    // where an empty string might be. So we simply replace *all* empty strings with null
    const newVehicle: Vehicle = Object.keys(vehicle).reduce((output, key) => {
      output[key] = vehicle[key] === '' ? null : vehicle[key]
      return output
    }, {})

    this.$axios
      .$post('vehicles', newVehicle) // may need to do some kind of id resolution here since POST doesn't have field sets
      .then((data) => {
        const vehicle = data.basic
        if (vehicle.id) {
          if (vehicle.campusOwnerByuId && newVehicle?.campusOwner?.byuId === vehicle.campusOwnerByuId) {
            vehicle.campusOwner = newVehicle.campusOwner
          }
          if (vehicle.stateOwnerByuId && newVehicle?.stateOwner?.byuId === vehicle.stateOwnerByuId) {
            vehicle.stateOwner = newVehicle.stateOwner
          }
          if (vehicle.campusOwner?.byuId) {
            dispatch('demographics/loadByByuId', vehicle.campusOwner.byuId, { root: true })
          } else {
            commit('demographics/setPerson', null, { root: true })
          }

          commit('setSelected', vehicle)
          dispatch('loadAdditionalInfo')
        }
      })
      .finally(() => commit('setSaving', false))
  },
  updateVehicle ({ state, commit, dispatch }, vehicle: Vehicle) {
    commit('setSaving', true)

    // API is picky, doesn't like empty strings for some fields (e.g. dates) but allows null
    // where an empty string might be. So we simply replace *all* empty strings with null
    const newVehicle = Object.keys(vehicle).reduce((output, key) => {
      output[key] = vehicle[key] === '' ? null : vehicle[key]
      return output
    }, {})

    this.$axios
      .$put(`vehicles/${vehicle.id}`, newVehicle) // possibly need id resolution
      .then((data) => {
        const vehicle = data.basic
        if (vehicle.id) {
          if (state?.edit?.campusOwner) {
            vehicle.campusOwner = state.edit.campusOwner
            dispatch('demographics/loadByByuId', state.edit.campusOwner.byuId, { root: true })
          } else {
            commit('demographics/setPerson', null, { root: true })
          }
          if (state?.edit?.stateOwner) {
            vehicle.stateOwner = state.edit.stateOwner
          }
          commit('setSelected', vehicle)
          dispatch('loadAdditionalInfo')
        }
      })
      .finally(() => commit('setSaving', false))
  },
  changeLicensePlate (
    { commit },
    { vehicle, stateCode, licenseNumber }: { vehicle: Vehicle; stateCode: string; licenseNumber: string }
  ) {
    // ONLY pass through base vehicle keys (i.e. ignore "history", "notes", "towFlag", etc)
    const newVehicle: Vehicle = {}
    Object.keys(vehicle).forEach((key) => {
      if (vehicle[key] === '') {
        // API requires NULL instead of empty strings
        newVehicle[key] = null
      } else {
        newVehicle[key] = vehicle[key]
      }
    })
    newVehicle.id = undefined
    newVehicle.stateCode = stateCode
    newVehicle.licensePlate = licenseNumber

    commit('setSaving', true)
    return this.$axios
      .$post(`vehicles/${vehicle.id}`, newVehicle)
      .then((data) => {
        const newVehicle = data.basic
        newVehicle.campusOwner = vehicle.campusOwner
        newVehicle.stateOwner = vehicle.stateOwner
        newVehicle.stateOwnerByuId = vehicle.stateOwnerByuId
        newVehicle.campusOwnerByuId = vehicle.campusOwnerByuId
        commit('setSelected', newVehicle)
      })
      .finally(() => commit('setSaving', false))
  },
  reloadNotes ({ state, commit, dispatch }) {
    if (!state.selected || !state.selected.id) {
      return
    }

    const id = state.selected.id

    const promise = this.$axios.$get('notes', { params: { vehicleId: id } }).then((data) => {
      if (id !== state.selected?.id) {
        return
      }
      commit('updateNotes', data)
    })
    return dispatch('wrapLoading', { key: 'vehicles/notes', promise }, { root: true })
  },
  saveNote ({ dispatch }, note: Note) {
    return dispatch('notes/save', note, { root: true }).then(() => dispatch('reloadNotes'))
  },
  deleteNote ({ commit, dispatch }, note: Note) {
    return dispatch('notes/delete', note, { root: true }).then(() => {
      // Tried simply dispatching "reloadNotes", but for some reason
      // the underlying SOAP call still included the just-deleted note
      commit('removeNote', note)
    })
  },
  async createTowFlag ({ state, commit }, towFlag: TowFlag) {
    // removes zone and milliseconds from time
    towFlag.flagDate = new Date().toISOString().replace('Z', '').substring(0, 19)
    towFlag.flagTypeCode = 'TOW'
    const data = await this.$axios.$post('tow-flags', towFlag)
    if (state.selected?.id === towFlag.vehicleId) {
      commit('updateTowFlag', data.basic)
    }
  },
  async updateTowFlag ({ state, commit }, towFlag: TowFlag) {
    const data = await this.$axios.$put(`tow-flags/${towFlag.id}`, towFlag)
    if (state.selected?.id === towFlag.vehicleId) {
      commit('updateTowFlag', data.basic)
    }
  },
  async deleteTowFlag ({ commit }, id: number) {
    await this.$axios.$delete(`tow-flags/${id}`)
    commit('updateTowFlag', null)
  }
}
