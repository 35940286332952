import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState, CodeState, Code, LoadStatus } from '~/types'

export const state = (): CodeState => ({})

export const getters: GetterTree<CodeState, RootState> = {
  codeLoading: (_state, _getters, _rootState, rootGetters) => rootGetters.prefixIsLoading('codes'),

  longCodes: state =>
    Object.keys(state).reduce((output, key) => {
      output[key] = state[key].map(code => ({ ...code, text: `${code.value} - ${code.text}` }))
      return output
    }, {}),

  groupedLongCodes: (state, getters) =>
    Object.keys(state).reduce((output, key) => {
      output[key] = [{ header: 'ACTIVE' }].concat(
        getters.longCodes[key].filter(code => code.active),
        { header: 'INACTIVE' },
        getters.longCodes[key].filter(code => !code.active)
      )
      return output
    }, {}),

  codeMap: state => (code: string) => {
    if (!state[code]) {
      return {}
    }
    return state[code].reduce((output, code) => {
      output[code.codeId] = code.description
      return output
    }, {})
  },

  longCodeMap: state => (code: string) => {
    if (!state[code]) {
      return {}
    }
    return state[code].reduce((output, code) => {
      output[code.codeId] = `${code.codeId} -  ${code.description}`
      return output
    }, {})
  }
}

export const mutations: MutationTree<CodeState> = {
  setCodes (state, { type, codes }: { type: string; codes: Code[] }) {
    // Use "Vue.set(...)" because this "type" may or may not have already
    // been set in the existing state. If it hasn't, then we need to
    // ensure Vue creates the magic getters/setters
    Vue.set(state, type, codes)
  },
  addCode (state, code: Code) {
    if (code?.codesetId) {
      state[code.codesetId].push(code)
    }
  }
}

export const actions: ActionTree<CodeState, RootState> = {
  load ({ rootState, state, commit, dispatch }, types: string | string[]) {
    if (!Array.isArray(types)) {
      types = [types]
    }
    const needed: string[] = []
    types.forEach((type) => {
      if (
        rootState.loadStatus[`codes/${type}`] !== LoadStatus.LOADING &&
        rootState.loadStatus[`codes/${type}`] !== LoadStatus.LOADED
      ) {
        needed.push(type)
        commit('setCodes', { type, codes: [] })
        dispatch('loading', `codes/${type}`, { root: true })
      }
    })

    if (!needed.length) {
      return
    }

    return this.$axios
      .$get('codes', { params: { codesetIds: needed } })
      .then((data) => {
        const groups: any = {}
        data.forEach((code) => {
          code.value = code.codeId
          code.text = code.description
          if (!groups[code.codesetId]) {
            groups[code.codesetId] = []
          }
          groups[code.codesetId].push(code)
        })
        if (groups.CLR) {
          // For some reason, Color codes aren't sorted on the server
          groups.CLR.sort((a, b) => (a.description < b.description ? -1 : 1))
        }

        needed.forEach((type) => {
          commit('setCodes', { type, codes: groups[type] || [] })
          dispatch('loaded', `codes/${type}`, { root: true })
        })

        if (groups.VIO) {
          // If "Violation Type" is freshly loaded, then also load all its "Violation Code" subcodes
          const violationCodes = state.VIO.map(code => code.codeId)
          dispatch('load', violationCodes)
        }
      })
      .catch(() => {
        needed.forEach((type) => {
          dispatch('loadFailed', `codes/${type}`, { root: true })
        })
      })
  },

  reload ({ commit, dispatch }, type: string): void {
    commit('setCodes', { type, codes: [] })
    dispatch('unloaded', `codes/${type}`, { root: true })
    dispatch('load', type)
  },

  save ({ commit, dispatch }, code: Code): Promise<any> {
    if (code.integerData) {
      code.integerData = Number(code.integerData)
    }
    if (code.sortOrder) {
      code.sortOrder = Number(code.sortOrder)
    }
    if (code.dateTimeData && !code.dateTimeData.includes('T')) {
      code.dateTimeData += 'T00:00:00'
    }
    if (code.id) {
      return this.$axios.$put(`codes/${code.id}`, code).then(() => {
        dispatch('reload', code.codesetId)
      })
    } else {
      return this.$axios.$post('codes', code).then((data) => {
        commit('addCode', data)
      })
    }
  }
}
