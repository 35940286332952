import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { LetterState, LetterTemplate, LoadStatus, RootState } from '~/types'

const decisionLetterTypeMap = {
  '00': 'NOFINE',
  '01': 'NOFINE',
  '02': 'NOFINE',
  '03': 'NOFINE',
  '04': 'REDUCED',
  '05': 'FULLFINE',
  '06': 'FULLFINE',
  '07': 'NOFINE',
  '08': 'REDUCED',
  '09': 'REDUCED',
  10: 'NOFINE',
  11: 'FULLFINE',
  12: 'REDUCED',
  // Yes, there's no '13'
  14: 'FULLFINE',
  15: 'FULLFINE'
}

export const state = (): LetterState => ({
  templates: [],
  saving: false
})

export const getters: GetterTree<LetterState, RootState> = {
  templateOptions: (_state, _getters, _rootState, rootGetters) => (decisionCode?: string) => {
    const types = ['ALL']
    if (decisionCode && decisionLetterTypeMap[decisionCode]) {
      types.push(decisionLetterTypeMap[decisionCode])
    } else {
      types.push('FULLFINE', 'REDUCED', 'NOFINE')
    }
    return (rootGetters['codes/longCodes'].LTR || []).filter(code => types.includes(code.charData))
  },
  contentMap: state =>
    state.templates.reduce((output, template) => {
      if (template.letterType) {
        output[template.letterType!] = template.letterContent
      }
      return output
    }, {})
}

export const mutations: MutationTree<LetterState> = {
  setTemplates (state, templates: LetterTemplate[]) {
    state.templates = templates
  },
  updateTemplate (state, template: LetterTemplate) {
    const found = state.templates.find(item => item.id === template.id)
    if (found) {
      found.letterContent = template.letterContent
    }
  },
  addTemplate (state, template: LetterTemplate) {
    state.templates.push(template)
  },
  setSaving (state, saving: boolean) {
    state.saving = saving
  }
}

export const actions: ActionTree<LetterState, RootState> = {
  loadTemplates ({ rootState, commit, dispatch }) {
    if (
      rootState.loadStatus['letters/templates'] === LoadStatus.LOADED ||
      rootState.loadStatus['letters/templates'] === LoadStatus.LOADING
    ) {
      return
    }
    const promise = this.$axios.$get('letter-templates').then(templates => commit('setTemplates', templates))
    dispatch('wrapLoading', { key: 'letters/templates', promise }, { root: true })
    dispatch('codes/load', 'LTR', { root: true })
  },

  saveTemplate ({ commit }, template: LetterTemplate) {
    commit('setSaving', true)
    this.$axios
      .$put(`letter-templates/${template.id}`, template)
      .then(() => {
        commit('updateTemplate', template)
      })
      .finally(() => commit('setSaving', false))
  },

  createTemplate ({ commit }, letterType: string) {
    commit('setSaving', true)
    return this.$axios
      .$post('letter-templates', { letterType, letterContent: 'Place content here.' })
      .then((template) => {
        if (template?.id) {
          commit('addTemplate', template)
        }
        return template
      })
      .finally(() => commit('setSaving', false))
  }
}
