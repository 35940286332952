import { ActionTree, MutationTree } from 'vuex'
import { PaidParkingState, ParkingZone, Person, RootState, Vehicle } from '~/types'

export const state = (): PaidParkingState => ({
  activePerson: null,
  activeVehicle: null,
  activeByuId: null,
  paidParking: [],
  activeVehicles: [],
  activeParkingZone: null
})

export const mutations: MutationTree<PaidParkingState> = {
  setActivePerson (state, person: Person | null) {
    state.activePerson = person
    state.activeByuId = person?.byuId
    state.activeVehicle = null
  },
  setActiveVehicle (state, vehicle: Vehicle | null) {
    state.activeVehicle = vehicle
    state.activeByuId = vehicle?.campusOwnerByuId
    state.activePerson = null
  },
  setActivePaidParking (state, history: []) {
    if (Object.entries(history).length === 0 && history.constructor === Object) {
      history = []
    } else {
      state.paidParking = history
    }
  },
  setActiveVehicles (state, vehicles: any) {
    state.activeVehicles = vehicles
  },
  setActiveParkingZone (state, parkingZone: ParkingZone | null) {
    state.activeParkingZone = parkingZone
  },
  reset (state) {
    state.activePerson = null
    state.activeVehicle = null
    state.activeByuId = null
    state.paidParking = []
    state.activeParkingZone = null
    state.activeVehicles = []
  }
}

export const actions: ActionTree<PaidParkingState, RootState> = {
  getByPerson ({ state, commit, dispatch }, person: Person | null) {
    commit('reset')

    if (!person?.byuId) {
      return
    }

    commit('setActivePerson', person)

    const paid = this.$axios
      .$get('paid-parking', {
        params: { byuId: person.byuId, fieldSets: ['basic', 'owner'] }
      })
      .then((data) => {
        if (person.byuId !== state.activeByuId) {
          // User has moved to a different person
          return
        }
        if (!data?.values?.length) {
          this.$dialog.warning({
            title: 'Not Found',
            text: 'No Paid Parking records found for this person',
            showClose: false,
            actions: ['OK']
          })
          return
        }
        commit('setActivePaidParking', data.values)
      })

    dispatch('wrapLoading', { key: 'paidParking/paid', promise: paid }, { root: true })

    dispatch('loadZoneAndVehicles', { state, commit, dispatch })
  },

  getByVehicle ({ state, commit, dispatch }, vehicle: Vehicle | null) {
    commit('reset')

    if (!vehicle?.id) {
      return
    }

    commit('setActiveVehicle', vehicle)

    const paid = dispatch('vehicles/findById', vehicle.id, { root: true }).then((vehicle) => {
      if (!vehicle) {
        this.$dialog.warning({
          title: 'Not Found',
          text: 'No Paid Parking records found for this vehicle',
          showClose: false,
          actions: ['OK']
        })
      } else {
        dispatch('person/searchByByuId', vehicle.campusOwnerByuId, { root: true }).then((person) => {
          dispatch('getByPerson', person)
        })
      }
    })

    dispatch('wrapLoading', { key: 'paidParking/paid', promise: paid }, { root: true })

    dispatch('loadZoneAndVehicles', { state, commit, dispatch })
  },

  loadZoneAndVehicles ({ state, commit, dispatch }) {
    if (!state.activeByuId) {
      return
    }

    const byuId = state.activeByuId

    const zones = this.$axios.$get('privileges', { params: { byuId } }).then((parkingZone) => {
      commit('setActiveParkingZone', parkingZone)
    })
    dispatch('wrapLoading', { key: 'paidParking/zones', promise: zones }, { root: true })

    const vehicles = this.$axios.$get('vehicles', { params: { campusOwnerByuId: byuId } }).then((data) => {
      if (!data?.values) {
        return
      }
      if (byuId === state.activeByuId) {
        commit(
          'setActiveVehicles',
          data.values.map(vehicle => vehicle.basic)
        )
      }
    })
    dispatch('wrapLoading', { key: 'paidParking/vehicles', promise: vehicles }, { root: true })
  }
}
