
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class ErrorLayout extends Vue {
  @Prop() error!: any

  mounted () {
    if (this.error.statusCode === 404) {
      this.$router.replace({ name: 'citations' })
    }
  }
}
