import { ActionTree, MutationTree } from 'vuex'
import { Note, NoteState, RootState } from '~/types'

// TODO: Add NoteType constants for "DEFAULT" and "APL_LTR" (maybe in /types instead of here in /store)

export const state = (): NoteState => ({
  saving: false
})

export const mutations: MutationTree<NoteState> = {
  setSaving (state, status: boolean) {
    state.saving = status
  }
}

export const actions: ActionTree<NoteState, RootState> = {
  save ({ commit }, note: Note) {
    commit('setSaving', true)
    if (note.id) {
      return this.$axios.$put(`notes/${note.id}`, note).finally(() => commit('setSaving', false))
    } else {
      return this.$axios.$post('notes', note).finally(() => commit('setSaving', false))
    }
  },
  delete ({ commit }, note: Note) {
    commit('setSaving', true)
    return this.$axios.$delete(`notes/${note.id}`).finally(() => commit('setSaving', false))
  }
}
