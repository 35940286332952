import Vue from 'vue'
import { MutationTree, ActionTree } from 'vuex'
import LRUCache from 'lru-cache'
import parsePerson from '~/assets/js/person-search-parse'
import { Person, PersonSearchParam, PersonState, RootState } from '~/types'

const byuIdCache = new LRUCache({ max: 10 })
// Less data, so larger cache size
const nameIdCache = new LRUCache({ max: 50 })
const nameByuIdCache = new LRUCache({ max: 50 })

export const state = (): PersonState => ({
  searchText: {},
  searchCancel: {}
})

export const mutations: MutationTree<PersonState> = {
  setSearchText (state, data: PersonSearchParam) {
    Vue.set(state.searchText, data.key, data.val)
    state.searchCancel[data.key] = null
  },
  setSearchCancel (state, data: PersonSearchParam) {
    state.searchCancel[data.key] = data.func
  },
  clearSearch (state, key: string) {
    Vue.set(state.searchText, key, '')
    if (state.searchCancel[key]) {
      state.searchCancel[key]()
      state.searchCancel[key] = null
    }
  },
  clearCache () {
    // Primarily used just for clean testing
    byuIdCache.clear()
    nameIdCache.clear()
    nameByuIdCache.clear()
  }
}

export const actions: ActionTree<PersonState, RootState> = {
  fillPersonByuId ({ dispatch }, { byuId, obj, field }) {
    if (!obj) {
      return
    }
    if (!(field in obj)) {
      Vue.set(obj, field, undefined)
    }

    // Temporary patch for reversed data
    // TODO: get rid of this after API fix is live
    if (obj[field]?.name === byuId) {
      obj[field].name = obj[field].byuId
      obj[field].byuId = byuId
    }

    if (obj[field]?.byuId === byuId) {
      // Already populated, just make sure "name" fields are all filled in
      if (obj[field].name && !obj[field].displayName) {
        obj[field].displayName = obj[field].name
      }
      if (obj[field].displayName && !obj[field].name) {
        obj[field].name = obj[field].displayName
      }
      return
    }

    if (!byuId || !/[0-9]{9}/.test(byuId?.trim())) {
      obj[field] = { name: byuId, byuId, displayName: byuId }
      return
    }

    const cached = nameByuIdCache.get(byuId)
    if (cached) {
      obj[field] = cached
      return
    }

    obj[field] = { name: 'Loading...', byuId: 'Loading...', displayName: 'Loading...' }
    dispatch('searchByByuId', byuId)
      .then((person: Person) => {
        const displayName = `${person.name} (${person.byuId})`
        nameByuIdCache.set(byuId, { name: person.name, byuId: person.byuId, displayName })
        obj[field] = { name: person.name, byuId: person.byuId, displayName }
      })
      .catch(() => (obj[field] = { name: byuId, byuId, displayName: byuId }))
  },
  fillEmployeeStatus (_, persons: Person[]) {
    const byuIds = persons.map((person) => {
      Vue.set(person, 'employeeType', 'Loading...')
      return person.byuId
    })
    this.$axios.$get(`person/worker/${byuIds.join(',')}`)
      .then(data => persons.forEach((person) => {
        const employee = data[person.byuId ?? '']?.[0]
        person.employeeType = [employee?.employeeClass, employee?.employeeStatus, employee?.employeeStanding].filter(x => x).join('/')
      }))
      .catch(() => persons.forEach((person) => {
        person.employeeType = '[Error]'
      }))
  },
  fillStudentStatus (_, persons: Person[]) {
    const byuIds = persons.map((person) => {
      Vue.set(person, 'studentStatus', 'Loading...')
      return person.byuId
    })
    this.$axios.$get(`person/student/${byuIds.join(',')}`)
      .then(data => persons.forEach((person) => {
        person.studentStatus = data[person.byuId ?? '']
      }))
      .catch(() => persons.forEach((person) => {
        person.studentStatus = '[Error]'
      }))
  },
  searchByByuId (_, byuId: string) {
    if (!byuId) {
      return {}
    }

    const cached = byuIdCache.get(byuId)
    if (cached) {
      return cached
    }

    const promise = this.$axios
      .$get(`${process.env.apiBaseUrl}/byuapi/identities/v2/${byuId}`)
      .then((data) => {
        const parsed = parsePerson({ values: [data] })
        const person = parsed.people[0]
        byuIdCache.set(byuId, person)
        return person
      })
      .catch(() => {
        byuIdCache.delete(byuId)
        return null
      })

    byuIdCache.set(byuId, promise)

    return promise
  }
}
