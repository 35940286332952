import { ActionTree, MutationTree } from 'vuex'
// eslint-disable-next-line import/named
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import LRUCache from 'lru-cache'
import camelcaseObjectDeep from 'camelcase-object-deep'
import { Demographics, DemographicsState, RootState } from '~/types'

const fullCache = new LRUCache<string, any>({ max: 5 })
const summaryCache = new LRUCache<string, any>({ max: 20 })

let currentlyLoadingByuId: string = ''
let currentlySearchingAddress: any = null

export const state = (): DemographicsState => ({
  person: null,
  addressSearchResponse: null,
  preview: null
})

export const mutations: MutationTree<DemographicsState> = {
  setPerson (state, person: Demographics | null) {
    currentlyLoadingByuId = ''
    if (person?.basic) {
      person.basic.name = person.basic.fullName
      person.basic.displayName = person.basic.byuId ? `${person.basic.name} (${person.basic.byuId})` : person.basic.name
    }
    state.person = person
  },
  setAddressSearchData (state, data: any) {
    if (data.length >= 1) {
      state.addressSearchResponse = data
    }
  },
  setAddressSearchResponse (state, data: any | null) {
    state.addressSearchResponse = data
  },
  setPreview (state, person: Demographics | null) {
    state.preview = person
  },
  mergePreview (state, data: any) {
    // Preview data comes from person/summary and person/loadDemographics,
    // so we have this "merge" mutation to load the "preview" data in separate steps
    state.preview = Object.assign(state.preview, data)
  }
}

export const actions: ActionTree<DemographicsState, RootState> = {
  // Convenience wrappers
  loading ({ dispatch }, key: string) {
    dispatch('loading', `demographics/${key}`, { root: true })
  },
  loaded ({ dispatch }, key: string) {
    dispatch('loaded', `demographics/${key}`, { root: true })
  },
  unloaded ({ dispatch }, key: string) {
    dispatch('unloaded', `demographics/${key}`, { root: true })
  },
  loadFailed ({ dispatch }, key: string) {
    dispatch('loadFailed', `demographics/${key}`, { root: true })
  },

  getSummary (_, byuId: string) {
    return cachedLoad({
      axios: this.$axios,
      cache: summaryCache,
      url: `demographics/${byuId}?fieldSets=basic`
    })
  },
  getDetails (_, byuId: string) {
    return cachedLoad({
      axios: this.$axios,
      cache: fullCache,
      url: `demographics/${byuId}?fieldSets=basic,relationship,phone,address,term,job,housing,affiliation`
    })
  },
  setPerson ({ commit, dispatch }, person: Demographics | null) {
    commit('setPerson', person)
    dispatch('vehicles/demographicsChanged', person, { root: true })
    dispatch('citations/demographicsChanged', person, { root: true })
    dispatch(person ? 'loaded' : 'unloaded', 'person')
  },
  async loadByByuId ({ state, dispatch }, byuId?: string) {
    if (!byuId) {
      dispatch('setPerson', null)
      return
    }
    if (state.person?.basic?.byuId === byuId) {
      // Current person is already loaded
      return state.person
    }

    currentlyLoadingByuId = byuId

    dispatch('loading', 'person')
    const person = await dispatch('getDetails', byuId).catch(() => null)
    if (person?.relationships?.length) {
      person.relationships = person.relationships.filter(rel => rel.type !== 'Managed_identity')
    }

    if (byuId !== currentlyLoadingByuId) {
      // A different demographics search started while we were waiting for the axios call to return
      // so we don't need these results any more
      return
    }

    return dispatch('setPerson', person)
  },
  searchAddress ({ commit, dispatch }, address: any) {
    currentlySearchingAddress = cloneDeep(address)
    const params = pickBy(address)
    const promise = this.$axios.$get('demographic-search', { params }).then((data) => {
      if (isEqual(address, currentlySearchingAddress)) {
        commit('setAddressSearchData', data)
      }
    })
    dispatch('wrapLoading', { key: 'demographics/address', promise }, { root: true })
  },
  personPreview ({ state, commit, dispatch }, byuId: string | null) {
    if (!byuId) {
      commit('setPreview', null)
      return
    }

    commit('setPreview', { basic: { byuId } })

    const promise = dispatch('getSummary', byuId).then((data) => {
      if (state.preview?.basic?.byuId !== byuId) {
        return
      }
      commit('mergePreview', data)
    })
    dispatch('wrapLoading', { key: 'demographics/previewSummary', promise }, { root: true })

    const detailsPromise = dispatch('getDetails', byuId).then((data) => {
      if (state.preview?.basic?.byuId !== byuId) {
        return
      }
      if (data?.relationships?.length) {
        data.relationships = data.relationships.filter(rel => rel.type !== 'Managed_identity')
      }
      commit('mergePreview', data)
    })
    dispatch('wrapLoading', { key: 'demographics/summary', promise: detailsPromise }, { root: true })
  }
}

function cachedLoad ({ axios, cache, url }: { axios: NuxtAxiosInstance; cache: LRUCache<string, any>; url: string }) {
  if (cache.has(url)) {
    return cache.get(url)
  }
  const promise = axios
    .$get(`${url}`)
    .then((data) => {
      data = camelcaseObjectDeep(data)
      cache.set(url, data)
      return data
    })
    .catch((e) => {
      cache.delete(url)
      throw e
    })
  cache.set(url, promise)

  return promise
}
