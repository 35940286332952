export * from './state'
export * from './appeals'
export * from './citations'
export * from './codes'
export * from './fines'
export * from './guest'
export * from './letters'
export * from './notes'
export * from './officers'
export * from './overnight'
export * from './person'
export * from './demographics'
export * from './pageInfo'
export * from './privileges'
export * from './vehicles'

export enum LoadStatus {
  UNLOADED,
  LOADING,
  LOADED,
  FAILED,
}

export interface TableHeader {
  text?: string
  value: string
  align?: 'start' | 'center' | 'end'
  sortable?: boolean
  filterable?: boolean
  divider?: boolean
  class?: string | string[]
  width?: string | number
  filter?: (value: any, search: string, item: any) => boolean
  sort?: (a: any, b: any) => number
}
