import Vue from 'vue'
import { VSelect, VAutocomplete, VTextField } from 'vuetify/lib'

// Small override to remove the tabindex for the "clear" icon on some Vuetify components
// Only necessary to use these "custom-*" versions if the component is using the `clearable` property

const overrideVuetify = () => {
  Vue.component('CustomVSelect', overrideIconTabindex(VSelect))
  Vue.component('CustomVAutocomplete', overrideIconTabindex(VAutocomplete))
  Vue.component('CustomVTextField', overrideIconTabindex(VTextField))
}

const overrideIconTabindex = (Model) => {
  return {
    extends: Model,
    methods: {
      genIcon (type, cb, extraData) {
        const icon = Model.options.methods.genIcon.call(this, type, cb, extraData)
        if (type === 'clear') {
          icon.children[0].data.attrs.tabindex = '-1'
        }
        return icon
      }
    }
  }
}

export default () => {
  // Bump to the end of the event queue, so this happens *after* Vuetify plugin
  setTimeout(overrideVuetify)
}
