import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { RootState, Person, LoadStatus } from '~/types'

// This list is arranged hierarchically; if the user is in one of these roles, then they're *also* automatically
// in all of the following roles in the list
const roles = [
  { key: 'developer', group: 'PARKING-DEVELOPER' },
  { key: 'admin', group: 'PARKING-ADMIN' },
  { key: 'employee', group: 'PARKING-EMPLOYEE' },
  { key: 'dispatch', group: 'PARKING-DISPATCH' },
  { key: 'readonly', group: 'PARKING-READONLY' },
  { key: 'overnight', group: 'TRAFFIC-OVERNIGHT' },
  { key: 'guest', group: 'TRAFFIC-GUEST' }
]

// TODO: after all the pages/components are built, re-audit any reference to "userRoles" in old site

export const state = (): RootState => ({
  authenticated: false,
  user: {},
  loadStatus: {},
  tinymceInitReadOnly: {
    plugins: 'autoresize fullscreen paste print lists',
    branding: false,
    menubar: false,
    browser_spellcheck: true,
    paste_as_text: true,
    toolbar: 'print | fullscreen',
    fontsize_formats: '10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
    setup (ed) {
      ed.on('BeforeSetContent', (evt) => {
        if (!evt.content.match(/<\/[^>]+>/)) {
          // If no closing HTML tags are currently in content (because letters use the format <D>), then assume old raw text and insert line breaks;
          evt.content = evt.content.replace(/\r?\n/g, '<br />')
        }
      })
    }
  },
  tinymceInit: {
    plugins: 'autoresize fullscreen paste print lists',
    branding: false,
    menubar: false,
    browser_spellcheck: true,
    paste_as_text: true,
    toolbar:
      'undo redo | print | styleselect | fontsizeselect bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fullscreen',
    fontsize_formats: '10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
    setup (ed) {
      ed.on('BeforeSetContent', (evt) => {
        if (!evt.content.match(/<\/[^>]+>/)) {
          // If no closing HTML tags are currently in content (because letters use the format <D>), then assume old raw text and insert line breaks;
          evt.content = evt.content.replace(/\r?\n/g, '<br />')
        }
      })
    }
  }
})

export const getters: GetterTree<RootState, RootState> = {
  isLoading: state =>
    Object.keys(state.loadStatus).reduce((output, key) => {
      output[key] = state.loadStatus[key] === LoadStatus.LOADING
      return output
    }, {}),

  prefixIsLoading: state => (prefix: string) =>
    Object.keys(state.loadStatus).reduce((output, key) => {
      if (key.startsWith(`${prefix}/`)) {
        const code = key.replace(`${prefix}/`, '')
        output[code] = state.loadStatus[key] === LoadStatus.LOADING
      }
      return output
    }, {}),

  username: state => state.user?.name?.displayName || state.user?.name?.givenName || ''
}

export const mutations: MutationTree<RootState> = {
  setLoadStatus (state, { key, status }: { key: string; status: LoadStatus }) {
    if (state.loadStatus[key] === undefined) {
      Vue.set(state.loadStatus, key, status)
    } else {
      state.loadStatus[key] = status
    }
  },
  authenticate (state, user) {
    state.user = user
    state.authenticated = true
  },
  addUserRole (state, role: string) {
    enableRoles(state, role)
  },
  toggleUserRole (state, role: string) {
    if (state.user?.roles?.[role]) {
      disableRoles(state, role)
    } else {
      enableRoles(state, role)
    }
  }
}

export const actions: ActionTree<RootState, RootState> = {
  // Convenience wrappers for "setLoadStatus" mutation
  loading ({ commit }, key: string) {
    commit('setLoadStatus', { key, status: LoadStatus.LOADING })
  },
  loaded ({ commit }, key: string) {
    commit('setLoadStatus', { key, status: LoadStatus.LOADED })
  },
  unloaded ({ commit }, key: string) {
    commit('setLoadStatus', { key, status: LoadStatus.UNLOADED })
  },
  loadFailed ({ commit }, key: string) {
    commit('setLoadStatus', { key, status: LoadStatus.FAILED })
  },

  wrapLoading ({ commit }, { key, promise }: { key: string; promise: Promise<any> }) {
    commit('setLoadStatus', { key, status: LoadStatus.LOADING })
    return promise
      .then((response) => {
        commit('setLoadStatus', { key, status: LoadStatus.LOADED })
        return response
      })
      .catch(() => commit('setLoadStatus', { key, status: LoadStatus.FAILED }))
  },

  async authenticate ({ commit }, rawUser: Person) {
    const user = cloneDeep(rawUser)
    user.roles = {}
    const groupIds = roles.map(role => role.group)
    const data = await this.$axios.$get(`${process.env.apiBaseUrl}/byuapi/identities/v2/${user.byuId}/group_memberships`, {
      params: { group_ids: groupIds.join(',') }
    })
    commit('authenticate', user)
    if (Array.isArray(data?.values)) {
      // eslint-disable-next-line camelcase
      const userGroups = data.values.filter(value => value?.group_id?.value).map(value => value.group_id.value)
      roles.forEach((role) => {
        if (userGroups.includes(role.group)) {
          commit('addUserRole', role.key)
        }
      })
    }
  }
}

// Enable specified role AND all "lower" roles
function enableRoles (state: RootState, key: string) {
  if (!state.user?.roles) {
    return
  }

  let found = false
  roles.forEach((role) => {
    if (!found && role.key === key) {
      found = true
    }
    if (found) {
      Vue.set(state.user!.roles!, role.key, true)
    }
  })
}

// Disable specified role AND all "higher" roles
function disableRoles (state: RootState, key: string) {
  if (!state.user?.roles) {
    return
  }

  let found = false
  roles.forEach((role) => {
    if (found || role.key === 'developer') {
      return
    }
    Vue.set(state.user!.roles!, role.key, false)
    if (role.key === key) {
      found = true
    }
  })
}
