import Vue from 'vue'
import { Context } from '@nuxt/types'

const colors = {
  citations: '#800000',
  vehicles: '#002e5d',
  appeals: '#009e96',
  demographics: '#008000',
  privileges: '#616161',
  overnight: '#cb7b00',
  guest: '#cb7b00',
  misc: '#cb7b00'
}

const initializeThemeSwitch = (context: Context) => {
  // If app.router hasn't yet been initialized, then bump this plugin's initialization to the end of the event queue
  if (!context.app?.router?.afterEach) {
    setTimeout(() => initializeThemeSwitch(context))
    return
  }

  // Use Vue.nextTick to ensure that the new page has fully loaded before we change the theme colors
  context.app.router.afterEach(to =>
    Vue.nextTick(() => {
      const name = to?.name?.split?.('-')[0] ?? ''
      if (colors[name]) {
        context.$vuetify.theme.currentTheme.primary = colors[name]
        // Sigh. Vuetify-dialog is super handy, but it kind of breaks Vuetify.
        // We need to trigger a full theme rebuild by changing the reactive "dark" property
        context.$vuetify.theme.dark = false

        // AND there's not a clean way (at least that I've found) to check when the broken Vuetify
        // theme is fully rebuilt. So we need to arbitrarily wait a few milliseconds and trigger it again
        setTimeout(() => (context.$vuetify.theme.dark = false), 100)

        // SIIIIGH... one last catch-all
        setTimeout(() => (context.$vuetify.theme.dark = false), 1000)
      }
    })
  )
}

export default initializeThemeSwitch
